import React, { useEffect, useRef, useState } from "react";
import "./banner.css";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Icon } from "@iconify/react";
import { Modal } from "antd";
const Banner = () => {
  const [selectedType, setSelectedType] = useState(false);
  const datacheck = localStorage.getItem("loggedin");

  const localdata = JSON.parse(datacheck);
  const [showSelNum, setShowSelNum] = useState(false);
  const navigate = useNavigate();
  const [confirmaccount, setconfirmaccount] = useState("");
  const [showinfo, setshowinfo] = useState(false);

  const [selectedpay1, setselectedpay1] = useState(false);
  const [selectedpay2, setselectedpay2] = useState(false);

  const [arrowfrom, setarrowfrom] = useState(true);
  const [arrowto, setarrowto] = useState(true);

  const [payWayes, setPayWayes] = useState([]);
  const [payWayes2, setPayWayes2] = useState([]);

  const [paysent, setpaysent] = useState("");
  const [moneyrecive, setmoneyrecive] = useState("");

  const [message, setmessage] = useState("");

  const [paymount, setpaymount] = useState("");

  const [showx1, setshowx1] = useState(false);

  const [acountnumber, setacountnumber] = useState("");

  const [acounttype, setacounttype] = useState("");

  const [paymentmethods, setpaymentmethods] = useState([]);
  const [pays, setPays] = useState(false);

  const [kind1, setkind1] = useState("");
  const [kind2, setkind2] = useState("");

  const [abbrevmoney1, setabbrevmoney1] = useState("");
  const [abbrevmoney2, setabbrevmoney2] = useState("");

  const [mony_send, setmoney_send] = useState("");

  const [ratetxt, setratetxt] = useState("");

  const [confshow, setconfshow] = useState(false);

  const [confnumber, setconfnumber] = useState("");

  const [icon1, seticon1] = useState("");
  const [icon2, seticon2] = useState("");

  const [notes, setnotes] = useState("");
  const [userWallets, setUserWallets] = useState([]);
  const getUserWallets = () => {
    const data_send = {
      user_id: localdata?.user_id,
    };
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/select_user_account.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (Array.isArray(res.data.message)) {
          let allData = [...res.data.message];
          setUserWallets(
            allData.map((item) => {
              return { ...item, checked: false };
            })
          );
        }
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    axios
      .get("https://ahmed-cash.com/ahmed_cash/user/select_wallets.php")
      .then((res) => {
        // console.log(res.data.message);
        if (res.data.status === "success") {
          setPayWayes(res.data.message);
          setPayWayes2(res.data.message);
        }
      })
      .catch((err) => console.log(err));
    getUserWallets();
  }, []);

  const [treatmentlist, settreatmentlist] = useState([]);
  const gettreatments = () => {
    const data_send = {
      // user_id:JSON.parse(datacheck).user_id||0,
      user_id: localdata?.user_id || 0,
    };
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/select_my_order.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        settreatmentlist(res.data.message);
      })
      .catch((err) => console.log(err));
  };

  const getnote = () => {
    axios
      .get("https://ahmed-cash.com/ahmed_cash/user/select_home_info.php")
      .then((res) => {
        // console.log(res.data);
        setnotes(res.data.message);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    gettreatments();
    getnote();
  }, []);

  const handleconfirm = () => {
    if (datacheck == null) {
      toast.warn("سجل اولا");
      return;
    }
    if (acountnumber != confirmaccount) {
      toast.warn("تأكد من انك تدخل الرقم الصحيح");
      return;
    }
    const data_send2 = {
      user_from_id: localdata.user_id,
    };
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/check_user_pending_order.php",
        JSON.stringify(data_send2)
      )
      .then((res) => {
        if (res.data.status == "faild") {
          toast.warn(res.data.message);
        } else {
          let payselected1 = payWayes.filter(
            (item) => item.wallet_id == selectedpay1 * 1
          );
          // console.log(payselected1[0]?.rate*1);
          let myrate = 0;
          let payselected2 = payWayes2.filter(
            (item) => item.wallet_id == selectedpay2 * 1
          );
          if (payselected1[0]?.type == "foreign") {
            if (mony_send * 1 < payselected1[0]?.minimum_order_val * 1) {
              toast.warn("يجب ان ييكون المبلغ المرسل اكبر من قيمه العمله");
              return;
            }
            myrate = payselected1[0]?.buy_rate;
          } else if (payselected2[0]?.type == "foreign") {
            myrate = payselected2[0]?.sell_rate;

            if (moneyrecive * 1 < payselected2[0]?.minimum_order_val * 1) {
              toast.warn("يجب ان ييكون المبلغ المستقبل اكبر من قيمه للتحويل");
              return;
            }
          }

          const data_send = {
            type1: kind1,
            type2: kind2,
            account: acountnumber,
            amount_sent: mony_send,
            received_quantity: moneyrecive,
            wallet_from_id: selectedpay1,
            wallet_to_id: selectedpay2,
            icon1,
            icon2,
            rate: myrate,
          };
          navigate("/conftreat", { state: { item: { ...data_send } } });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (selectedpay1 || selectedpay2) {
      setPays(
        paymentmethods.filter(
          (item) =>
            item.type !=
            paymentmethods.filter((item) => item.wallet_id == selectedpay1)[0]
              ?.type
        )
      );
    } else {
      setPays(paymentmethods);
    }
    let payselected = payWayes.filter(
      (item) => item.wallet_id == selectedpay1 * 1
    );
    // console.log(payselected[0]?.type)

    let newpays2arr = payWayes.filter(
      (item) => item.type !== payselected[0]?.type
    );

    setPayWayes2(newpays2arr);
    setselectedpay2(newpays2arr[0]?.wallet_id);
    setmoney_send("");
    setmoneyrecive("");
    setmessage("");
    setkind2(newpays2arr[0]?.wallet_name);

    setacounttype(payselected[0]?.wallet_name);
    // if(payselected[0]?.type=="foreign"){
    // setabbrevmoney1("USD");
    // setabbrevmoney2("LE")
    // setratetxt(`1USD -> ${payselected[0]?.buy_rate}`);
    // }
    // else {
    // setabbrevmoney1("LE");
    // setabbrevmoney2("USD")
    // setratetxt(`1USD -> ${newpays2arr[0]?.sell_rate}`);
    // }
    seticon2(newpays2arr[0]?.wallet_logo);
  }, [selectedpay1]);
  useEffect(() => {
    let payselected = payWayes2.filter(
      (item) => item.wallet_id == selectedpay2 * 1
    );

    setacounttype(payselected[0]?.wallet_name);
  }, [selectedpay2]);

  useEffect(() => {
    let payselected1 = payWayes.filter(
      (item) => item.wallet_id == selectedpay1 * 1
    );
    let payselected2 = payWayes2.filter(
      (item) => item.wallet_id == selectedpay2 * 1
    );

    if (payselected1[0]?.type == "foreign") {
      // console.log("first")
      setmessage(
        `يجب ان تكون اقل قيمه للتحويل ${payselected1[0]?.minimum_order_val} دولار`
      );
      setabbrevmoney1("USD");
      setabbrevmoney2("L.E");
      setratetxt(`1USD -> ${payselected1[0]?.buy_rate}`);
      setmoneyrecive("");
    } else if (payselected2[0]?.type == "foreign") {
      // console.log("second")
      setmessage(
        `يجب ان تكون اقل قيمه للتحويل ${payselected1[0]?.minimum_order_val} جنيه`
      );
      setabbrevmoney1("LE");
      setabbrevmoney2("USD");
      setratetxt(`1USD -> ${payselected2[0]?.sell_rate}`);
      setmoneyrecive("");
    }
  }, [selectedpay2, selectedpay1]);

  // useEffect(()=>{
  //   console.log(paymentmethods);
  //   if(mony_send){
  //   let fromarr=pays.filter((item)=>item.wallet_id*1==selectedpay1*1);
  //   let toarr=pays.filter((item)=>item.wallet_id*1==selectedpay2*1);
  //   }
  // },[mony_send, paymentmethods])

  // useEffect(() => {
  //   if (selectedpay1 != "") {
  //     setselectedpay2(
  //       paymentmethods.filter(
  //         (item) =>
  //           item.type !==
  //           paymentmethods.filter((item) => item.wallet_id == selectedpay1)[0]?.type
  //       )[0]?.id
  //     );
  //   } else {
  //     setselectedpay2("");
  //   }
  // }, [pays]);
  const [searchQuery, setSearchQuery] = useState(false);
  const [payes, setPayes] = useState(paymentmethods);
  const [payese, setPayese] = useState(pays);
  // useEffect(() => {
  //   console.log(searchQuery);
  //   if (searchQuery != "") {
  //     setPayes(
  //       paymentmethods.filter((item) => item.wallet_name.includes(searchQuery))
  //     );

  //   } else {
  //     setselectedpay2("");
  //     setPayes(paymentmethods)
  //   }
  // }, [searchQuery]);
  const paymountVal = useRef();
  const paysentVal = useRef();

  useEffect(() => {
    // console.log(mony_send)
    if (selectedpay1) {
      let payselected = payWayes.filter(
        (item) => item.wallet_id == selectedpay1 * 1
      );
      // if(payselected[0].rate>moneyrecive){
      //   toast.warn("يجب ان يكون المبلغ المستلم اكبر من قيمه العمله")
      // }
      // console.log(payselected[0].type=="foreign")
      if (payselected[0].type == "foreign") {
        // console.log()
        setmoneyrecive(
          mony_send * 1 * (payselected[0]?.buy_rate * 1).toFixed(2)
        );
      } else {
        let payselected2 = payWayes2.filter(
          (item) => item.wallet_id == selectedpay2 * 1
        );
        // setmoneyrecive((mony_send*1)*(payselected[0].buy_rate*1).toFixed(2))
        setmoneyrecive(
          ((mony_send * 1) / (payselected2[0]?.sell_rate * 1)).toFixed(2)
        );
      }
    }
  }, [mony_send]);

  // console.log(localdata)
  const CheckIfBlocked = () => {
    if (localdata !== null && localdata !== undefined) {
      const data_send = {
        user_id: localdata?.user_id,
      };
      axios
        .post(
          "https://ahmed-cash.com/ahmed_cash/user/select_user_info.php",
          JSON.stringify(data_send)
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.message.ban == "yes") {
            localStorage.removeItem("loggedin");
            window.location.reload();
            toast.warn("تواصل نع الدعم");
          }
          if (res.data.message == null) {
            localStorage.removeItem("loggedin");
            window.location.reload();
            toast.warn("تواصل نع الدعم");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    CheckIfBlocked();
  }, []);

  return (
    <div className="banner">
      <div
        className={
          selectedpay2 !== "" && selectedpay1 !== ""
            ? "pay_form act"
            : "pay_form"
        }
      >
        <h2>تحويل</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <h5 style={{ marginBottom: "4px" }}>إرسال من:</h5>
          <div className="form_pay">
            <input
              onClick={() => {
                setarrowfrom(!arrowfrom);
                if (selectedpay1 !== "") {
                  setarrowfrom(!arrowfrom);
                }
              }}
              type="text"
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setarrowfrom(false);
                setarrowto(true);
              }}
              placeholder={selectedpay1 ? "" : "إرسال من"}
              // disabled={!selectedpay1 ? true : false}
              ref={paysentVal}
              // value={!selectedpay1 ? paysentVal?.current?.value : paysent}
            />
            {arrowfrom ? (
              <img
                style={
                  {
                    // maxHeight:'100%'
                  }
                }
                className="arrimg"
                onClick={() => {
                  setarrowfrom(false);
                  setarrowto(true);
                }}
                src={require("../../../assets/bottomarrow.png")}
                alt=""
              />
            ) : (
              <img
                className="arrimg"
                onClick={() => {
                  setarrowfrom(true);
                }}
                src={require("../../../assets/uparrow.png")}
                alt=""
              />
            )}
            <div className="selectedpay">
              {payWayes
                .filter((item) => item.wallet_id == selectedpay1 * 1)
                .map((item) => {
                  return (
                    <div
                      onClick={() => {
                        // console.log("ere")
                        setarrowfrom(!arrowfrom);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        width: "100%",
                      }}
                      className="selected_payment_method"
                    >
                      <img
                        style={{ width: "40px" }}
                        src={item.wallet_logo}
                        alt=""
                      />
                      <span>{item.wallet_name}</span>
                      <span>({item.type == "egyptian" ? "LE" : "USD"})</span>
                    </div>
                  );
                })}
            </div>
            {!arrowfrom ? (
              <div className="payment_methods">
                {payWayes
                  .filter((it) => {
                    if (it.hidden == "no" || it.hidden == "for_time") {
                      return { ...it };
                    } else return null;
                  })
                  .map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          if (item.hidden == "no") {
                            setselectedpay1(item.wallet_id);
                            // console.log(item.wallet_id)
                            setarrowfrom(true);
                            setSearchQuery(false);
                            setkind1(item.wallet_name);
                            seticon1(item.wallet_logo);
                          }
                        }}
                        className={`${
                          item.hidden == "for_time"
                            ? "payment_method for_time"
                            : "payment_method"
                        }`}
                      >
                        <img
                          style={{ width: "40px" }}
                          src={item.wallet_logo}
                          alt=""
                        />
                        <span>{item.wallet_name}</span>
                        {item.hidden == "for_time" ? (
                          <span>(الخدمه موقوفه لفتره معينه)</span>
                        ) : null}
                        <span>({item.type == "egyptian" ? "LE" : "USD"})</span>
                      </div>
                    );
                  })}
              </div>
            ) : null}
          </div>
          <h5 style={{ marginBottom: "4px" }}>إرسال إلى:</h5>

          <div className="to_pay">
            <input
              onClick={() => {
                if (selectedpay1 !== "") {
                  // setarrowto(!arrowto)
                  if (selectedpay1 !== false) {
                    setarrowto(!arrowto);
                  } else {
                    toast.warn("أختر طريقه الارسال أولا");
                  }
                  // console.log("ewew")
                }
              }}
              type="text"
              // onFocus={() => {
              //   setarrowfrom(true);
              //   if(selectedpay1!==false){
              //   setarrowto(false);
              //   }
              //   else {
              //     toast.warn("أختر طريقه الارسال أولا")
              //   }
              // }}
              // onBlur={() => {
              //   setarrowto(true);
              // }}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setarrowto(false);
              }}
              placeholder={selectedpay2 ? "" : "إرسال إلى"}
              // disabled={selectedpay2 ? true : false}
              ref={paymountVal}

              // value={!selectedpay2 ? paymountVal?.current?.value : paymount}
            />

            {arrowto ? (
              <img
                className="arrimg"
                onClick={() => {
                  if (selectedpay1 !== false) {
                    setarrowto(false);
                  } else {
                    toast.warn("أختر طريقه الارسال اولا");
                  }
                }}
                src={require("../../../assets/bottomarrow.png")}
                alt=""
              />
            ) : (
              <img
                className="arrimg"
                onClick={() => {
                  setarrowto(true);
                }}
                src={require("../../../assets/uparrow.png")}
                alt=""
              />
            )}
            <div className="selectedpay">
              {payWayes2
                .filter((item) => item.wallet_id == selectedpay2 * 1)
                .map((item) => {
                  return (
                    <div
                      onClick={() => {
                        console.log("ewe");
                        setarrowto(!arrowto);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                      className="selected_payment_method"
                    >
                      <img
                        style={{ width: "40px" }}
                        src={item.wallet_logo}
                        alt=""
                      />
                      <span>{item.wallet_name}</span>
                      <span>({item.type == "egyptian" ? "LE" : "USD"})</span>
                    </div>
                  );
                })}
            </div>
            {!arrowto ? (
              <div className="payment_methods">
                {payWayes2
                  ?.filter((it) => {
                    if (it.hidden == "no" || it.hidden == "for_time") {
                      return { ...it };
                    } else return null;
                  })
                  .map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          if (item.hidden == "no") {
                            setselectedpay2(item.wallet_id);
                            setarrowto(true);
                            setSearchQuery(false);
                            setkind2(item.wallet_name);
                            seticon2(item.wallet_logo);
                          }
                        }}
                        className={
                          item.hidden == "for_time"
                            ? "payment_method for_time"
                            : "payment_method"
                        }
                      >
                        <img
                          style={{ width: "20px" }}
                          src={item.wallet_logo}
                          alt=""
                        />
                        <span>{item.wallet_name}</span>
                        {item.hidden == "for_time" ? (
                          <span>(الخدمه موقوفه لفتره معينه)</span>
                        ) : null}
                        <span>({item.type == "egyptian" ? "LE" : "USD"})</span>
                      </div>
                    );
                  })}
              </div>
            ) : null}
          </div>
          {selectedpay1 !== false ? (
            <div className="payment_other_details">
              <div className="type_send">
                <h5 style={{ marginBottom: "4px" }}>
                  المبلغ المرسل ({abbrevmoney1})
                </h5>
                <input
                  value={mony_send}
                  onChange={(e) => {
                    setpaysent(e.target.value);
                    setmoney_send(e.target.value);
                  }}
                  // onBlur={() => {
                  //   setshowx1(false);
                  // }}
                  onFocus={() => {
                    setshowx1(true);
                  }}
                  type="text"
                  placeholder={`المبلغ المرسل ${abbrevmoney1}`}
                />
              </div>
              <div>
                <div className="money_send">
                  <h5 style={{ marginBottom: "4px" }}>
                    الكميه المستلمه ({abbrevmoney2})
                  </h5>
                  <input
                    disabled={true}
                    style={{ cursor: "no-drop" }}
                    value={moneyrecive}
                    onChange={(e) => {
                      setpaymount(e.target.value);
                    }}
                    type="text"
                    placeholder={`الكميه المستلمه ${abbrevmoney2}`}
                  />
                </div>
              </div>
              <div>
                <div>
                  <div className="sel_div_acc">
                    <h5>رقم حساب ({acounttype})</h5>
                    <button
                      onClick={() => {
                        setShowSelNum(true);
                      }}
                    >
                      إختيار من المسجل
                    </button>
                  </div>
                  <input
                    value={acountnumber}
                    onChange={(e) => {
                      setacountnumber(e.target.value);
                    }}
                    type="text"
                    placeholder={`رقم حساب (${acounttype})`}
                  />
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                <span></span>
                {message !== "" ? <h5>{message}</h5> : null}
                {ratetxt !== "" ? <h4>{ratetxt}</h4> : null}
                <button
                  type="submit"
                  onClick={() => {
                    // handleconfirm();
                    if (acountnumber == "") {
                      toast.warn("ادخل رقم الحساب اولا");
                      return;
                    }
                    if (selectedType == false) {
                      setconfshow(true);
                    } else {
                      handleconfirm();
                    }
                  }}
                  style={{
                    width: "200px",
                    maxWidth: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "none",
                    color: "white",
                    backgroundColor: "#0d6efd",
                    marginTop: "4px",
                    cursor: "pointer",
                  }}
                >
                  التالى
                </button>
              </div>
            </div>
          ) : null}
        </form>
      </div>
      {confshow ? (
        <div className="confshow">
          <AiOutlineClose
            onClick={() => {
              setconfshow(false);
            }}
          />
          <input
            // value={acountnumber}
            onChange={(e) => {
              setconfirmaccount(e.target.value);
            }}
            type="text"
            placeholder={`رقم حساب (${acounttype})`}
          />
          <button
            onClick={() => {
              handleconfirm();
            }}
          >
            تأكيد
          </button>
        </div>
      ) : null}
      <div className="notes">
        <img src={require("../../../assets/noteimg.png")} alt="" />
        <p>{notes}</p>
      </div>
      <Modal
        title="إختيار رقم"
        open={showSelNum}
        onOk={() => {
          console.log("");
          setShowSelNum(false);
        }}
        onCancel={() => {
          console.log("");
          setShowSelNum(false);
        }}
      >
        <div className="wallets">
          {userWallets.map((item, index) => {
            return (
              <div key={index}>
                <h4>
                  <span>{item.account_type}</span>
                </h4>
                <p>{item.num}</p>
                <button
                  onClick={() => {
                    let allData = [...userWallets];
                    setacounttype(item.num);
                    setacountnumber(item.num);
                    setconfirmaccount(item.num);
                    // setconfshow
                    setSelectedType(true);
                    setUserWallets(
                      allData.map((it, ind) => {
                        if (it.wallet_id == item.wallet_id) {
                          return { ...it, checked: !it.checked };
                        } else return { ...it, checked: false };
                      })
                    );
                  }}
                  className={item.checked ? "sel_one active" : "sel_one"}
                >
                  إختيار
                </button>
              </div>
            );
          })}
        </div>
      </Modal>
      {/* <img onClick={()=>{
        setshowinfo(!showinfo);
      }} src={require("../../../assets/info.png")} className="not_info" alt="" /> */}
    </div>
  );
};

export default Banner;
