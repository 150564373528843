import axios from "axios";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "./signstep2.css";
import { toast } from "react-toastify";
const Step2Sign = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [vefcode, setvefcode] = useState("");
  const { state } = location;
  const { data_send, code } = location.state;
  const [signdata, setsigndata] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  const [confpass, setconfpass] = useState("");
  const handlesub = () => {
    if (signdata.password !== confpass) {
      toast.warn("تأكد من تأكيد كلمة المرور");
      return;
    }
    if (vefcode !== code) {
      toast.warn("هذا الكود خاطئ");
      return;
    }
    const data_send = {
      user_name: state.user_name,
      user_email: state.email,
      phone: state.phone,
      password: state.password,
      confirm_identity_front: state?.faceLink,
      confirm_identity_back: state?.backFaceLink,
      invitation_code: state?.invitation_code ? state?.invitation_code : null,
    };
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/signup_step2.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          toast.success("تم تسجيل الدخول بنجاح");
          let newdata = {
            ...data_send,
            user_id: res.data.message,
          };
          // console.log(newdata)
          localStorage.setItem("loggedin", JSON.stringify(newdata));
          window.location.href =  window.location.origin + "/treatmentlist"
          // toast.success(res.data.message);
        } else if (res.data.status === "error") {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      });
  };
  return (
    <div className="signstep2">
      <div
        className="form"
        // onSubmit={(e) => {
        //   e.preventDefault();
        //   handlesub();
        // }}
      >
        <input
          value={vefcode}
          onChange={(e) => {
            setvefcode(e.target.value);
          }}
          type="text"
          placeholder="أدخل كود التأكيد"
        />
        <button
          onClick={() => {
            handlesub();
          }}
        >
          تسجيل
        </button>
      </div>
    </div>
  );
};

export default Step2Sign;
