import React, { useEffect, useState } from "react";
import "./header.css";
import "./header.css";
import { BsList } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { MdAttachMoney } from "react-icons/md";
import { CiLogout } from "react-icons/ci";
import { FaRegCircleUser } from "react-icons/fa6";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
// import { Icon } from "@iconify/react";
const Header = () => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);
  const datacheck = localStorage.getItem("loggedin");
  const [userData, setUserData] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const getUSerData = () => {
    try {
      const data_send = {
        user_id: JSON.parse(datacheck)?.user_id,
      };
      axios
        .post(
          "https://ahmed-cash.com/ahmed_cash/user/select_user_info.php",
          JSON.stringify(data_send)
        )
        .then((res) => {
          if (res.data.message?.user_id) setUserData(res.data.message);
          else setUserData(null);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setPageLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUSerData();
  }, []);
  // console.log(datacheck)
  return (
    <div className={toggle ? "header" : "header active"}>
      <div
        onClick={() => {
          navigate("/");
        }}
        className="logo"
      >
        <img
          // style={{ width: "50px" }}
          src={require("../../assets/logo_ahmed_cash-01-removebg-preview (1).png")}
          alt=""
        />
      </div>
      <React.Fragment>
        <div className="links">
          <NavLink to={"/"} onClick={() => setToggle(true)}>
            تحويل
          </NavLink>
          {userData == null ? null : (
            <NavLink
              style={{ display: "flex", alignItems: "center" }}
              to={"/userdata"}
              onClick={() => setToggle(true)}
            >
              <span>البيانات الشخصيه</span>
              <FaRegCircleUser />
            </NavLink>
          )}

          <NavLink
            style={{ display: "flex", alignItems: "center" }}
            to={"/support"}
            onClick={() => setToggle(true)}
          >
            <span>الدعم</span>
            <BiSupport />
          </NavLink>
          {console.log("userData", userData)}
          {userData == null || userData?.partners != 0 ? null : (
            <NavLink
              style={{ display: "flex", alignItems: "center" }}
              to={"/Partice"}
              onClick={() => setToggle(true)}
            >
              انضم كشريك <BiSupport />
            </NavLink>
          )}
          {userData == null ? null : (
            <NavLink
              style={{ display: "flex", alignItems: "center" }}
              to={"/treatmentlist"}
              onClick={() => setToggle(true)}
            >
              <span>سجل المعاملات</span>
              <MdAttachMoney />
            </NavLink>
          )}
          {userData == null ? (
            <NavLink
              onClick={() => {
                setToggle(true);
              }}
              to={"/regist"}
            >
              تسجيل
            </NavLink>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
              }}
              role="button"
              onClick={() => {
                localStorage.removeItem("loggedin");
                navigate("/regist", { replace: true });
              }}
            >
              <CiLogout style={{ cursor: "pointer", fontSize: "19px" }} />
              <span>تسجيل الخروج</span>
            </div>
          )}
        </div>

        <div className="menu-toggle" onClick={() => setToggle(!toggle)}>
          <BsList />
        </div>
      </React.Fragment>
      {/* {!localStorage.getItem("loggedin") ? (
        <Navigate to="/regist" />
      ) : (
        <React.Fragment>
          <div className="links">
            <NavLink to={"/"} onClick={() => setToggle(true)}>
              تحويل
            </NavLink>
            <NavLink
              style={{ display: "flex", alignItems: "center" }}
              to={"/support"}
              onClick={() => setToggle(true)}
            >
              <span>الدعم</span>
              <BiSupport />
            </NavLink>
            <NavLink
              style={{ display: "flex", alignItems: "center" }}
              to={"/treatments"}
              onClick={() => setToggle(true)}
            >
              <span>المعاملات</span>
              <MdAttachMoney />
            </NavLink>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
              }}
              role="button"
              onClick={() => {
                localStorage.removeItem("loggedin");
                navigate("/regist");
              }}
            >
              <CiLogout style={{ cursor: "pointer", fontSize: "19px" }} />
              <span>تسجيل الخروج</span>
            </div>
          </div>

          <div className="menu-toggle" onClick={() => setToggle(!toggle)}>
            <BsList />
          </div>
        </React.Fragment>
      )} */}
    </div>
  );
};

export default Header;
