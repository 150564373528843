import React, { useState } from 'react'
import './resetpass.css'
import axios from 'axios';
import { Spin } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
const ResetPassword = () => {
  const navigate=useNavigate();
  const [loading,setLoading]=useState(false)
  const [email,setEmail]=useState('');
  const [showCode,setShowCode]=useState(false)
  const [userCode,setUserCode]=useState('');
  const handleCheckCode=()=>{
    let rescashcodeData=localStorage.getItem("rescashcode")
    let code=JSON.parse(rescashcodeData);
    if(code==userCode){
      navigate("/remakepass",{replace:true,state:{email}})
    }
    else {
      toast.warn('هذا الكود خاطئ');
      return
    }
  }
  const handleReset=()=>{
    setLoading(true)
    let code = "";
    for (let i = 0; i < 4; i++) {
      code += Math.floor(Math.random() * 10);
    }
    const data_send={
      email,
      code
    }
    localStorage.setItem("rescashcode",code);
    axios.post("https://ahmed-cash.com/ahmed_cash/user/auth/send_code.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res.data)
      if(res.data.status=='error'){
        toast.error(res.data.message);
      }
      else {
        setShowCode(true)
        toast.success(res.data.message);
        localStorage.getItem('rescashcode',JSON.stringify(code))
      }
    }).finally(()=>{
      setLoading(false)
    })
  }
  return (
    <div className='resetpasspage'>
      <div className="reset_pass">
        {
          showCode?(
          <div className='rewritecode'>
            <form onSubmit={(e)=>{
              e.preventDefault()
            }} className='reworteform' action="">
              <div>
                <label htmlFor="">إكتب الكود الذى وصلك على البريد الإلكترونى</label>
                <input placeholder='إكتب الكود' type="text" onChange={(e)=>{
                  setUserCode(e.target.value)
                }} />
              </div>
              <div className='btn_code'>
                <button
                  onClick={()=>{
                    handleCheckCode()
                  }}
                >إرسال</button>
              </div>
            </form>
          </div>):(
            <form onSubmit={(e)=>{
              e.preventDefault();
              handleReset()
            }} className='reset_pass_form' action="">
              <h4>إعاده تعيين كلمة مرور</h4>
              <div>
                <label htmlFor="">البريد الإلكترونى:</label>
                <input onChange={(e)=>{
                  setEmail(e.target.value)
                }} type="text" placeholder='البريد الإلكترونى' />
              </div>
              <div className='btn_code'>
                <button disabled={loading} style={{cursor:loading?'no-drop':'pointer'}}>
                  {
                    loading?<Spin/>:'إرسال'
                  }
                </button>
              </div>
            </form>
          )
        }
      </div>
    </div>
  )
}

export default ResetPassword
