import React, { useEffect, useRef, useState } from "react";
import "./regist2.css";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import { Spin } from "antd";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import ImageInput from "../imageInput/ImageInput";
import { useSearchParams } from "react-router-dom";
const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};
const Regist2 = () => {
  const [params] = useSearchParams()
  const navigate = useNavigate();
  const [showpass, setshowpass] = useState(false);
  const [showpassconf, setshowpassconf] = useState(false);
  const [signdata, setsigndata] = useState({
    user_name: "",
    password: "",
    email: "",
    phone: "",
  });
  const [loading, setloading] = useState(false);
  const [confpass, setconfpass] = useState("");
  const [image_path, setimage_path] = React.useState("");
  const [nid_image, setNid_image] = React.useState(false);
  const [name, setName] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [address, setAddress] = useState("");
  const [streamVideo, setStreamVideo] = useState("");
  const [nid, setNid] = useState("");
  const [openedCamera, setOpenedCamera] = useState(false);
  const [faceLink, setFaceLink] = useState("");
  const [faceImage, setFaceImage] = useState();
  const [backFaceLink, setBackFaceLink] = useState("");
  const [backFaceimage, setBackFaceimage] = useState();
  const uploadImage = (file, setImage) => {
    setUploadLoading(true);
    const formData = new FormData();
    formData.append("image", file);
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/admin/item_img_uploader.php",
        formData
      )
      .then((res) => {
        if (res?.data?.status == "error") {
          toast.error("فشل في رفع الصورة");
        } else {
          setImage(res?.data?.message);
          toast.success("تم رفع الصورة بنجاح");
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setUploadLoading(false);
      });
  };
  const validateEmail = (email) => {
    var re = /^01[0125][0-9]{8}$/;
    var em =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

    return re.test(email);
  };

  const validatePassword = (password) => {
    var pass = /^[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    return pass.test(password);
  };
  const handlesub = () => {
    if (signdata.user_name == "") {
      toast.warn("أدخل إسمك");
      return;
    }
    if (signdata.email == "") {
      toast.warn("أدخل البريد الإلكترونى");
      return;
    }
    if (signdata.password == "") {
      toast.warn("أدخل كلمه المرور");
      return;
    }
    if (signdata.phone == "") {
      toast.warn("أدخل رقم هاتفك");
      return;
    }
    if (confpass !== signdata.password) {
      toast.warn("تأكد من تطابق كلمه المرور مع تأكيدها");
      return;
    }

    if (validateEmail(signdata.email)) {
      toast.warn("أدخل بريد إلكترونى سليم");
      return;
    }

    if (!validatePassword(signdata.password)) {
      toast.warn("أدخل كلمة سر قويه");
      return;
    }
    setloading(true);

    let code = "";
    for (let i = 0; i < 4; i++) {
      code += Math.floor(Math.random() * 10);
    }
    const data_send = {
      email: signdata.email,
      code,
    };
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/signup_step1.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.data.status == "success") {
          navigate("/sign", {
            state: { ...signdata, code, faceLink, backFaceLink, invitation_code: params?.get("invitation_code") },
            replace: true,
          });
          toast.success(res.data.message);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setloading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="registpage">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handlesub();
        }}
      >
        <div className="logsign">
          <h5
            onClick={() => {
              navigate("/login");
            }}
          >
            تسجيل
          </h5>
          <h5>حساب جديد</h5>
        </div>
        <label htmlFor="">أدخل اسمك</label>

        <input
          onChange={(e) => {
            setsigndata({ ...signdata, user_name: e.target.value });
          }}
          type="text"
          placeholder="أدخل إسمك"
        />
        <label htmlFor="">أدخل البريد الالكتروني</label>
        <input
          onChange={(e) => {
            setsigndata({ ...signdata, email: e.target.value });
          }}
          type="email"
          placeholder="أدخل البريد الإلكترونى"
        />
        <label htmlFor="">أدخل رقم الهاتف</label>
        <input
          onChange={(e) => {
            setsigndata({ ...signdata, phone: e.target.value });
          }}
          type="tel"
          placeholder="أدخل رقم الهاتف"
        />
        <label htmlFor="">أدخل كلمة المرور</label>
        <div className="passdiv">
          <input
            onChange={(e) => {
              setsigndata({ ...signdata, password: e.target.value });
            }}
            type={showpass ? "text" : "password"}
            placeholder="أدخل كلمة المرور"
          />
          {showpass ? (
            <AiFillEye
              onClick={() => {
                setshowpass(false);
              }}
            />
          ) : (
            <AiFillEyeInvisible
              onClick={() => {
                setshowpass(true);
              }}
            />
          )}
        </div>
        <label htmlFor="">إعادة إدخال كلمة المرور</label>
        <div className="passdiv">
          <input
            onChange={(e) => {
              setconfpass(e.target.value);
            }}
            type={showpassconf ? "text" : "password"}
            placeholder="تأكيد كلمة المرور"
          />
          {showpassconf ? (
            <AiFillEye
              onClick={() => {
                setshowpassconf(false);
              }}
            />
          ) : (
            <AiFillEyeInvisible
              onClick={() => {
                setshowpassconf(true);
              }}
            />
          )}
        </div>
        {/* <label htmlFor="">تأكيد الهوية</label>
        <div className="inputField">
          <label htmlFor="">صورة البطاقة </label>
          <div className="imageUploader">
            <div className="videoCamera" style={{ width: "240px" }}>
              <div className="videoCamera" style={{ width: "100%" }}>
                <video
                  ref={camera}
                  style={{ width: "100%" }}
                  autoPlay
                  playsInline
                  muted
                />
                {openedCamera ? null : (
                  <span className="btn btn-primary" onClick={openCamera}>
                    فتح الكاميرا
                  </span>
                )}
                {!openedCamera ? null : (
                  <span className="btn btn-primary" onClick={handleTakePhoto}>
                    التقاط
                  </span>
                )}{" "}
                {image && (
                  <img
                    src={image}
                    style={{ width: "100%", height: "200px" }}
                    alt="Taken photo"
                  />
                )}
              </div>
            </div>
            {faceImage ? (
              <span
                className="btn btn-success"
                onClick={() => {
                  return uploadLoading
                    ? null
                    : uploadImage(faceImage, setFaceLink);
                }}
              >
                {uploadLoading ? <Spin /> : "رفع الصورة"}
              </span>
            ) : null}
          </div>
        </div> */}

        {loading ? (
          <div>
            <Spin />
          </div>
        ) : (
          <button>إتمام التسجيل</button>
        )}
        <p
          style={{ fontSize: "14px", textAlign: "center" }}
          onClick={() => {
            navigate("/reset");
          }}
        >
          هل نسيت كلمة السر
        </p>
      </form>
    </div>
  );
};

export default Regist2;
