import React, { useState } from 'react'
import './remakepass.css'
import { FaRegEye ,FaRegEyeSlash} from 'react-icons/fa'
// import {IoIosEyeOff,IoEye} from 'react-icons/io5'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Spin } from 'antd'
const ReMakePass = () => {
  const datacheck=localStorage.getItem("loggedin");

  const localdata=JSON.parse(datacheck);

  const navigate=useNavigate()
  const location=useLocation();
  const [loading,setLoading]=useState(false)
  const [showPass,setShowPass]=useState(false);
  const [showConfPass,setConfShowPass]=useState(false);
  const [newPass,setNewPass]=useState('');
  const [confPass,setConfPass]=useState('');
  const handleRemakePass=()=>{
    if(newPass==''){
      toast.warn('يجب إدخال كلمة مرور');
      return
    }
    if(confPass!==newPass){
      toast.warn('تأكد من أن كلمة السر نفس إعاده كلمة السر');
      return;
    }
    setLoading(true)
    const data_send={
      email:location?.state?.email,
      new_password:newPass
    }
    axios.post("https://ahmed-cash.com/ahmed_cash/user/auth/reset_password.php",JSON.stringify(data_send))
    .then((res)=>{
      if(res.data.status=='success'){
        toast.success(res.data.message);
        // localStorage.setItem("loggedin",res.data.data);
        localStorage.removeItem('rescashcode');
        navigate('/login',{replace:true})
      }
      else if(res.data.status=='error'){
        toast.error(res.data.message)
      }
      else {
        toast.error("حدث خطأ ما")
        return;
      }
    }).catch(e=>console.log(e))
    .finally(()=>{
      setLoading(false)
    })
  }
  // if(!location.state){
  //   navigate(-1)
  // }
  return (
    <div className='remakepass'>
        <form onSubmit={(e)=>{
          e.preventDefault();
          handleRemakePass()
        }} className='repass_form' action="">
          <h4>إعاده تعيين كلمة المرور</h4>
          <div>
            <label htmlFor="">كلمة السر الجديده</label>
            <div>
              <input onChange={(e)=>{
                setNewPass(e.target.value)
              }} type={showPass?"text":'password'} />
              {
                showPass?(
                  <FaRegEye
                    onClick={()=>{
                      console.log("ee")
                      setShowPass(!showPass)
                    }}
                  />
                ):(
                  <FaRegEyeSlash
                    onClick={()=>{
                      setShowPass(!showPass)
                    }}
                  />
                )
              }
            </div>
          </div>
          <div>
            <label htmlFor="">إعاده كلمة السر</label>
            <div>
              <input onChange={(e)=>{
                setConfPass(e.target.value)
              }} type={showConfPass?"text":'password'} />
              {
                showConfPass?(
                  <FaRegEye
                    onClick={()=>{
                      setConfShowPass(!showConfPass)
                    }}
                  />
                ):(
                  <FaRegEyeSlash
                    onClick={()=>{
                      setConfShowPass(!showConfPass)
                    }}
                  />
                )
              }
            </div>
          </div>
          <div className='repassbth'>
            <button disabled={loading} style={{cursor:loading?'no-drop':'pointer'}}>
              {
                loading?<Spin/>:'تسجيل'
              }
            </button>
          </div>
        </form>
    </div>
  )
}

export default ReMakePass
