import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "./confirmtreat.css";
import { toast } from "react-toastify";
import PopUp from "../popUp";
import axios from "axios";
import { MdContentCopy } from "react-icons/md";
import { Spin } from "antd";
const ConfirmTreat = () => {
  const datalocal=localStorage.getItem("loggedin");
  // console.log(JSON.parse(datalocal))
  // console.log(JSON.parse(datalocal))
  const location = useLocation();
  const { item } = location.state;
  console.log(item)
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(false);
  const cancel = () => setShow(false);
  const [client_phone,setClientPhone]=useState("");
  const [confimg,setconfimg]=useState(null);
  const [loading,setloading]=useState(false);
  const confirm = () => {
    // toast.success("تم تنفيذ العملية بنجاح");
    const fromdata=new FormData();
    let n=0;
    fromdata.append("image",confimg);
    axios.post("https://ahmed-cash.com/ahmed_cash/admin/item_img_uploader.php",fromdata)
    .then((res)=>{
      if(res.data.status=="success"){
        setloading(true);
        n=1;
      }
      else if(res.data.status=="error"){
        // toast.error(res.data.message);
        setloading(true);
      }
      else {
        // toast.error("حدث خطأ ما")
        setloading(true);
      }
      const data_send={
        wallet_from_id:item.wallet_from_id,
        wallet_to_id:item.wallet_to_id,
        amount_sent:item.amount_sent,
        received_quantity:item.received_quantity,
        rate:item.rate,
        destination_address:item.account,
        source_address:client_phone,
        user_from_id:JSON.parse(datalocal).user_id||0,
        user_photo_url:n==1?res.data.message:'',
      }
      console.log(data_send)
      axios.post("https://ahmed-cash.com/ahmed_cash/user/make_order.php",JSON.stringify(data_send))
      .then((res)=>{
        if(res.data.status=="success"){
          toast.success("تم الطلب بنجاح");
          navigate("/treatmentlist")
        }
        else if(res.data.status=="error"){
          toast.error(res.data.message);
        }
        else{
          toast.error("حدث خطأ ما")
        }
      }).finally(()=>{
        setloading(false);
      }).catch(err=>console.log(err))
    })
    setShow(false);
  };
  const [companynumber,setcompanynumber]=useState("");
  const getcompanyphone=()=>{
    // console.log(item.wallet_to_id);
    axios.get("https://ahmed-cash.com/ahmed_cash/user/select_wallets.php")
    .then((res)=>{
      console.log(res.data.message)
      let alldata=[...res.data.message]
      // console.log(alldata)
      let arr=res.data.message[0]?.transfare_to_details.split(" ");
      for(let i=0;i<alldata.length;i++){
        console.log(alldata[i].transfare_to_address)
        if(item.wallet_from_id==alldata[i].wallet_id){
          setcompanynumber(alldata[i].transfare_to_address);
          // console.log(alldata[i].transfare_to_address,"ddddddddddd")
        }
      }
      // for (let i=0;i<arr.length;i++){
      //   if(arr[i]*1!=="NAN"){
      //     setcompanynumber(arr[i]);
      //   }
      // }
      // setcompanynumber(res.data.message[0].transfare_to_address)
    }).catch(err=>console.log(err))
  }
  useEffect(()=>{
    getcompanyphone()
  },[])

  function myFunction() {
    var copyText = document.getElementById("myInput");

    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  }

  return (
    <div className="confirm_page">
      {show ? (
        <PopUp
          title={"رسالة تأكيد"}
          text={"هل أنت متأكد من تأكيد العملية ؟"}
          closeFunction={cancel}
          // conFiremFunction={confirm}
        />
      ) : null}
      <div className="confirm_form">
        <h2>بيانات العملية</h2>
        <div className="v_T">
          <h3>الارسال</h3>
          <p className="v_v">
            <table>
              <thead>
                <tr>
                <th style={{textAlign:'center'}}>نوع</th>
                  <th style={{textAlign:'center'}}>القيمة </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                <td style={{textAlign:'center'}}>
                  <span style={{display:'flex',gap:'3px',alignItems:'center',justifyContent:'center'}}>
                  <img style={{width:'40px'}} src={item.icon1} alt="" />
                  <span>{item.type1}</span>
                  </span>
                </td>
                  <td style={{textAlign:'center',display:'flex',alignItems:'center',gap:'3px',justifyContent:'center'}}>
                    {/* <img style={{width:'50px'}} src={item.icon2} alt="" /> */}
                    <span>{item.amount_sent}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </p>

        </div>
        <div className="v_T">
          <h3>الاستقبال</h3>
          <p className="v_v">
            <table>
              <thead>
                <tr>
                <th style={{textAlign:'center'}}>نوع</th>
                  <th style={{textAlign:'center'}}>القيمة </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                <td style={{textAlign:'center',display:'flex',alignItems:'center',justifyContent:'center',gap:'3px'}}>
                  <img style={{width:'40px'}} src={item.icon2} alt="" />
                  <span>{item.type2}</span>
                </td>
                  <td style={{textAlign:'center'}}>{item.received_quantity}</td>
                </tr>
              </tbody>
            </table>
          </p>
        </div>


        {/* <div>
          <h3>الحاله</h3>
          <p style={{ display: "flex", alignItems: "center" }}>{item.status=="pending"?'موقوفه':''}</p>
        </div> */}


        {/* <div>
          <h3>تاريخ العمليه</h3>
          <p>06/07/2023</p>
        </div>
        <div>
          <h3>وقت العمليه</h3>
          <p>02:47 م</p>
        </div> */}
        <div>
          <h3>سعر الوحده</h3>
          <p>{item.rate}</p>
        </div>
        <div>
          <h3>الرقم المحول إليه</h3>
          <p>{item.account}</p>
        </div>
        <p>
          تأكد من جميع البيانات - ثم قم بتحويل المبلغ على رقم الحساب التالى
          وقم باالاحتفاظ ب سكرينه التحويل لاستخدامها عند الحاجه - ثم أدخل الرقم
          الذى أرسلت منه{" "}
        </p>
        <div className="copyinput">
        <input
        value={companynumber}
        id="myInput"
          type="text"
          placeholder="رقم/حساب فودافون كاش"
          // onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <MdContentCopy onClick={()=>{
          myFunction()
        }}/>
        </div>
        <input onChange={(e)=>{
          setClientPhone(e.target.value)
        }} type="text" placeholder="أدخل الرقم الذى حولت منه"/>
        <input type="file" onChange={(e)=>{
          setconfimg(e.target.files[0])
        }}/>
        <div className="btns">
          {
            loading?(
              <Spin/>
            ):(
              <button
            onClick={() => {
              if (client_phone) {
                setShow(true);
                confirm()
              } else {
                toast.error("برجاء إدخال رقم الهاتف");
              }
            }}
          >
            طلب تحويل
          </button>
            )
          }
          <button
            onClick={() => {
              toast.success("تم إلغاء العملية بنجاح");
              navigate("/treatmentlist");
            }}
          >
            {" "}
            إلغاء العمليه
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmTreat;
