import React, { useEffect, useState } from "react";
import "./userwallets.css";
import axios from "axios";
import { AiOutlinePlus } from "react-icons/ai";
import { Modal, Select } from "antd";
import { toast } from "react-toastify";
const UserWallets = () => {
  const datacheck = localStorage.getItem("loggedin");
  const localdata = JSON.parse(datacheck);
  const [allWallets, setAllWallets] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [addLoading, setAddLoading] = useState(false);
  const [showAddMoadl, setShowAddModal] = useState(false);
  const [newWalletData, setNewWalletData] = useState({
    phone: "",
    account_type: "",
    wallet_id: "",
  });
  const [wallets, setWallets] = useState([]);
  const getUserWallets = () => {
    const data_send = {
      user_id: localdata.user_id,
    };
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/select_user_account.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (Array.isArray(res.data.message)) {
          setWallets(res.data.message);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setPageLoading(false);
      });
  };
  const handleAddNewWallet = () => {
    setAddLoading(true);
    const data_send = {
      user_id: localdata.user_id,
      account_type: newWalletData.account_type,
      num: newWalletData.phone,
    };
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/add_account.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setShowAddModal(false);
          getUserWallets();
          toast.success(res.data.message);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setAddLoading(false);
      });
  };
  useEffect(() => {
    getUserWallets();
  }, []);
  return (
    <div className="wallets_user_page">
      <div className="user_wallets">
        <h4>
          <AiOutlinePlus
            onClick={() => {
              setShowAddModal(true);
            }}
          />
          <span>إضافه</span>
        </h4>
        <div className="wallets">
          {wallets.map((item, index) => {
            return (
              <div key={index}>
                <h4>
                  <span>{item.account_type}</span>
                </h4>
                <p>{item.num}</p>
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        title="إضافه محفظه جديده"
        open={showAddMoadl}
        onOk={() => {
          handleAddNewWallet();
        }}
        onCancel={() => {
          setShowAddModal(false);
        }}
      >
        <div className="newwal">
          <div>
            <label htmlFor="">إسم المحفظه:</label>
            <input
              type="text"
              onChange={(e) => {
                setNewWalletData({
                  ...newWalletData,
                  account_type: e.target.value,
                });
              }}
            />
          </div>
          <div>
            <label htmlFor="">رقم الهاتف</label>
            <input
              type="text"
              onChange={(e) => {
                setNewWalletData({ ...newWalletData, phone: e.target.value });
              }}
              placeholder="رقم الهاتف"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UserWallets;
