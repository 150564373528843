import React, { useEffect, useState } from "react";
import "./userdata.css";
import axios from "axios";
import { Skeleton, Spin } from "antd";
import { FaUserEdit } from "react-icons/fa";
import Modal from "../modal/index";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import CopyToClipboard from "react-copy-to-clipboard";
const UserData = () => {
  const navigate = useNavigate();
  const datacheck = localStorage.getItem("loggedin");
  const localdata = JSON.parse(datacheck);
  const [editLoading, setEditLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const [showAddMoadl, setShowAddModal] = useState(false);
  const [payWays, setPayWayes] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [newWalletData, setNewWalletData] = useState({
    phone: "",
    account_type: "",
    wallet_id: "",
  });
  const handleAddNewWallet = () => {
    // setAddLoading(true);
    const data_send = {
      user_id: localdata.user_id,
      account_type: newWalletData.account_type,
      num: newWalletData.phone,
    };
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/add_account.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setShowAddModal(false);
          // getUserWallets();
          toast.success(res.data.message);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        // setAddLoading(false);
      });
  };
  const [vfNumber, setVfNumber] = useState(null);
  const confirmGet = () => {
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/request_withdraw_partner.php",
        JSON.stringify({
          user_id: userData?.user_id,
          amount: userData?.partner_money,
          vf_number: vfNumber,
        })
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setShowPayModal(false);
          // getUserWallets();
          toast.success(res.data.message);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => {
        // setAddLoading(false);
      });
  };
  const getUSerData = () => {
    const data_send = {
      user_id: localdata?.user_id,
    };
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/select_user_info.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res.data.message);
        setUserData(res.data.message);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    axios
      .get("https://ahmed-cash.com/ahmed_cash/user/select_wallets.php")
      .then((res) => {
        console.log(res.data.message);
        if (res.data.status === "success") {
          setPayWayes(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const handleEdit = () => {
    setEditLoading(true);
    const data_send = {
      ...userData,
    };
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/edit_user_data.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          toast.success(res.data.message);
          getUSerData();
          setShowEditModal(false);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setEditLoading(false);
      });
  };
  useEffect(() => {
    getUSerData();
  }, []);

  return (
    <div className="user_data_page">
      <div className="user">
        {!pageLoading ? (
          <div className="userdata">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row-reverse",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/userwallets");
                }}
              >
                <img
                  style={{ width: "30px", cursor: "pointer" }}
                  src={require("../../assets/wallets.png")}
                  alt=""
                />
              </div>
              <h4
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaUserEdit
                  onClick={() => {
                    setShowEditModal(true);
                  }}
                />
                <span>البيانات الشخصيه</span>
              </h4>
            </div>
            <div>
              <h4>الإسم:</h4>
              <p>{userData.name}</p>
            </div>
            <div>
              <h4>البريد الإلكترونى:</h4>
              <p>{userData.email}</p>
            </div>
            <div>
              <h4>رقم الهاتف:</h4>
              <p>{userData.phone}</p>
            </div>
            <div>
              <h4>تاريخ التسجيل:</h4>
              <p>{userData.join_date}</p>
            </div>
            <h4>
              <span>الرصيد: </span>
              <span>{parseFloat(userData?.partner_money / 100)}جنية</span>
            </h4>
            {parseInt(userData?.partners) ? (
              <>
                <div>
                  {" "}
                  <h4 htmlFor="phone">رابط الدعوة :</h4>
                  <CopyToClipboard
                    text={
                      window.location.origin +
                      "/regist?invitation_code=" +
                      userData?.invitation_code
                    }
                    onCopy={() => toast.success("تم نسخ رابط الدعوة")}
                  >
                    <p>
                      {window.location.origin +
                        "/regist?invitation_code=" +
                        userData?.invitation_code}
                    </p>
                  </CopyToClipboard>
                </div>
                {/* {userData?.hasWallet ? null : (
                  <button
                    className="btn btn-success"
                    onClick={() => setShowAddModal(true)}
                  >
                    إضافة محفظة
                  </button>
                )} */}
                {parseFloat(userData?.partner_money) / 100 >= 100 ? (
                  <button
                    className="btn btn-success"
                    onClick={() => setShowPayModal(true)}
                  >
                    سحب الرصيد
                  </button>
                ) : null}{" "}
                <button
                  className="btn btn-success mx-3"
                  onClick={() => navigate("/Clients")}
                >
                  المنضم من خلالك
                </button>
                <button
                  className="btn btn-success mx-3"
                  onClick={() => navigate("/Transactions")}
                >
                  التحويلات
                </button>
              </>
            ) : null}
            <Modal
              headerTitle={"تعديل البيانات الشخصيه"}
              open={showEditModal}
              toggle={setShowEditModal}
              children={
                <form
                  className="edit_user_data"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleEdit();
                  }}
                >
                  <div>
                    <label htmlFor="name">الإسم:</label>
                    <input
                      id="name"
                      type="text"
                      onChange={(e) => {
                        setUserData({ ...userData, name: e.target.value });
                      }}
                      value={userData.name}
                    />
                  </div>
                  <div>
                    <label htmlFor="phone">رقم الهاتف:</label>
                    <input
                      id="phone"
                      type="text"
                      onChange={(e) => {
                        setUserData({ ...userData, phone: e.target.value });
                      }}
                      value={userData.phone}
                    />
                  </div>
                  <div></div>
                  <div className="btn_edit">
                    <button
                      disabled={editLoading}
                      style={{ cursor: editLoading ? "no-drop" : "pointer" }}
                    >
                      {editLoading ? <Spin /> : "تعديل"}
                    </button>
                  </div>
                </form>
              }
            />
            <Modal
              title="إضافه محفظه جديده"
              open={showAddMoadl}
              onOk={() => {
                handleAddNewWallet();
              }}
              toggle={setShowAddModal}
              onCancel={() => {
                setShowAddModal(false);
              }}
            >
              <div className="newwal">
                <div>
                  <label htmlFor="">إسم المحفظه:</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setNewWalletData({
                        ...newWalletData,
                        account_type: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="">رقم الهاتف</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setNewWalletData({
                        ...newWalletData,
                        phone: e.target.value,
                      });
                    }}
                    placeholder="رقم الهاتف"
                  />
                </div>
              </div>
            </Modal>

            <Modal
              title="سحب الرصيد"
              open={showPayModal}
              onOk={() => {
                // handleAddNewWallet();
              }}
              okText={"تأكيد"}
              cancelText={"إلغاء"}
              toggle={setShowPayModal}
              onCancel={() => {
                setShowPayModal(false);
              }}
            >
              <div className="newwal">
                <h4>
                  <span>الرصيد: </span>
                  <span>{parseFloat(userData?.partner_money) / 100}جنية</span>
                </h4>

                <div>
                  <label htmlFor=""> رقم محفظة فودافون كاش :</label>

                  <input
                    type="number"
                    onChange={(e) => setVfNumber(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid",
                      cursor: "pointer",
                    }}
                  />
                  <div className="rowDiv">
                    <button
                      className="btn btn-success"
                      onClick={() => confirmGet()}
                    >
                      تأكيد
                    </button>
                    <button className="btn btn-danger">إلغاء</button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        ) : (
          <Skeleton avatar paragraph={{ rows: 4 }} />
        )}
      </div>
    </div>
  );
};

export default UserData;
