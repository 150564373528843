import React, { useState } from "react";
import "./treatment.css";
import { AiOutlineQuestion } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router";
const Treatments = () => {
  const location=useLocation();
  const {item}=location?.state||{};
  const navigate = useNavigate();
  const [treatments,settreatments]=useState(item);
  // const [tratments, settratments] = useState([
  //   {
  //     money1: "556",
  //     kind1: "فودافون كاش",
  //     kind2: "اسكريل",
  //     money2: "546",
  //     numofop: "332g",
  //     status: "غير مؤكده",
  //   },
  //   {
  //     money1: "5566",
  //     kind1: "فودافون كاش",
  //     kind2: "اسكريل",
  //     money2: "5500",
  //     numofop: "332g",
  //     status: "غير مؤكده",
  //   },
  // ]);
  return (
    <div className="treatments_page">
      <div className="treatments">
        <h2>العمليات غير المؤكدة</h2>

        {/* {tratments.map((item, index) => {
          return (
            <div className="treatment">
              <div className="treatment_details">
                <p className="v_v">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>تم تحويله</th>
                        <th>تم استقباله</th>
                        <th>أمر تأكيد</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>المبلغ</td>
                        <td>{item.money1}</td>
                        <td>{item.money2}</td>
                      </tr>
                      <tr>
                        <td>النوع</td>
                        <td>{item.kind1}</td>
                        <td>{item.kind2}</td>
                        <td>
                          <button
                            onClick={() => {
                              navigate("/conftreat", { state: { item: item } });
                            }}
                          >
                            تأكيد
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </p>
              </div>
            </div>
          );
        })} */}
        {
          location.state!==null?(
              Object.keys(treatments)?.length>0?(
            <div className="treatment">
            <div className="treatment_details">
              <p className="v_v">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>تم تحويله</th>
                      <th>تم استقباله</th>
                      <th>أمر تأكيد</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>المبلغ</td>
                      <td>{treatments?.mony_send}</td>
                      <td>{treatments?.moneyrecive}</td>
                    </tr>
                    <tr>
                      <td>النوع</td>
                      <td>{treatments?.type1}</td>
                      <td>{treatments?.type2}</td>
                      <td>
                        <button
                          onClick={() => {
                            navigate("/conftreat", { state: { item: item } });
                          }}
                        >
                          تأكيد
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </p>
            </div>
          </div>
          ):(
            <h4>لا يوجد بيانات</h4>
          )
          ):(
            <div>
              <h4 style={{textAlign:'center'}}>لا يوجد بيانات</h4>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default Treatments;
