import React from 'react'
import './home.css'
// import Banner from './Banner/Banner'
import Banner2 from './Banner/Banner2'
const Home = () => {
  return (
    <div>
      <Banner2/>
    </div>
  )
}

export default Home
