import axios from "axios";
import "./userdata.css";
import { Modal, Select, Space, Table } from "antd";
import { useEffect, useState } from "react";
const Clients = () => {
  const renderdata = [
    {
      title: "الرقم التعريفي للشخص",
      dataIndex: "user_id",
    },
    // {
    //   title: "البريد الالكتروني",
    //   dataIndex: "email",
    // },
    // {
    //   title: "رقم التليفون",
    //   key: "phone",
    // },
  ];
  const [data, setData] = useState([]);
  const datacheck = localStorage.getItem("loggedin");
  const localdata = JSON.parse(datacheck);
  useEffect(() => {
    axios
      .post("https://ahmed-cash.com/ahmed_cash/admin/select_user_from_partner.php", {
        partner_id: localdata?.user_id,
      })
      .then((res) => {
        if (res.data.status === "success") {
          setData(res?.data?.message);
        }
      });
  }, []);
  return (
    <div className="user_data_page">
      <div className="user">
        <Table dataSource={data} columns={renderdata} />
      </div>
    </div>
  );
};

export default Clients;
