import React, { useEffect, useState } from "react";
import "./support.css";
import axios from "axios";
const Support = () => {
  const [socials,setSocials]=useState([]);
  const getsocialaccounts=()=>{
    axios.get("https://ahmed-cash.com/ahmed_cash/admin/select_social_acc.php")
    .then((res)=>{
      setSocials(res.data.message);
    })
  }
  useEffect(()=>{
    getsocialaccounts()
  },[])
  return (
    <div className="confirm_page support_page">
      <div className="support_s">
        <h2> تواصل معنا من خلال </h2>

        {
          socials.map((item,index)=>{
            return(
              <a target="_blank" href={item.link} className="suppord_card">
                <div className="left_side">
                  <p>{item.name}</p>
                </div>
                <div className="right_side">
                  <img
                    src={item.image}
                    alt="WhatsApp"
                  />
                </div>
              </a>
            )
          })
        }
      </div>
    </div>
  );
};

export default Support;
