import axios from 'axios';
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';

const ResetPass = () => {
  const navigate=useNavigate();
  const [new_password,setnew_password]=useState("");
  const location=useLocation();
  const {state}=location;

  const handlesub=()=>{
    const data_send={
      email:state.email,
      new_password,
    }
    axios.post("https://ahmed-cash.com/ahmed_cash/user/reset_password.php",JSON.stringify(data_send))
    .then((res)=>{
      if(res.data.status=="success"){
        navigate("/login")
        toast.success(res.data.message)
      }
      else if(res.data.status=="error"){
        toast.error(res.data.message);
      }
      else {
        toast.error("حدث خطأ ما")
      }
    }).catch(err=>console.log(err))
  }
  return (
    <div className='signstep2'>
      <div className="form"
            // onSubmit={(e) => {
            //   e.preventDefault();
            //   handlesub();
            // }}
          >
            <input
            value={new_password}
              onChange={(e) => {
                setnew_password(e.target.value);
              }}
              type="text"
              placeholder="أدخل كلمة السر الجديده"
            />
            <button
            onClick={()=>{
              handlesub()
            }}
            >تسجيل</button>
          </div>
    </div>
  )
}

export default ResetPass
