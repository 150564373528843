import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

const ForgetPass = () => {
  const navigate=useNavigate();
  const [email,setemail]=useState("");
  const handlesub=()=>{
    let code = "";
    for (let i = 0; i < 8; i++) {
      code += Math.floor(Math.random() * 10);
    }
    const data_send={
      email,
      code,
      is_new: 0
    }
    axios.post("https://ahmed-cash.com/ahmed_cash/user/send_code.php",JSON.stringify(data_send))
    .then((res)=>{
      if(res.data.status=="success"){
        navigate("/recode",{state:{...data_send}})
        toast.success(res.data.message)
      }
      else if(res.data.status=="faild"){
        toast.error(res.data.message);
      }
      else {
        toast.error("حدث خطأ ما")
      }
    }).catch(err=>console.log(err))
  }
  return (
    <div className='signstep2'>
      <div className="form"
            // onSubmit={(e) => {
            //   e.preventDefault();
            //   handlesub();
            // }}
          >
            <input
            value={email}
              onChange={(e) => {
                setemail(e.target.value);
              }}
              type="text"
              placeholder="أدخل البريد الإلكترونى"
            />
            <button
            onClick={()=>{
              handlesub()
            }}
            >تسجيل</button>
          </div>
    </div>
  )
}

export default ForgetPass
