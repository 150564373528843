import React, { useState } from "react";
import { useNavigate } from "react-router";
import "./login.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
const Login = () => {
  const [showpass, setshowpass] = useState(false);
  const [logindata, setlogindata] = useState({
    user_email: "",
    password: "",
  });
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const handlelogin = () => {
    if (logindata.user_email == "") {
      toast.warn("أدخل البريد الإلكترونى");
      return;
    }
    if (logindata.password == "") {
      toast.warn("أدخل كلمة المرور");
      return;
    }
    setloading(true);
    const data_Send = {
      ...logindata,
    };
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/user_login.php",
        JSON.stringify(data_Send)
      )
      .then((res) => {
        if (res.data.status == "succese") {
          toast.success("تم تسجيل الدخول بنجاح");
          localStorage.setItem("loggedin", JSON.stringify(res.data.message));
          window.location.href = "/treatmentlist";
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setloading(false);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="registpage loginpage">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handlelogin();
        }}
      >
        <div className="signlogin">
          <h5>تسجيل</h5>
          <h5
            onClick={() => {
              navigate("/regist");
            }}
          >
            حساب جديد
          </h5>
        </div>
        <input
          onChange={(e) => {
            setlogindata({ ...logindata, user_email: e.target.value });
          }}
          type="text"
          placeholder="أدخل البريد الإلكترونى"
        />
        <div className="passdiv">
          <input
            onChange={(e) => {
              setlogindata({ ...logindata, password: e.target.value });
            }}
            type={showpass ? "text" : "password"}
            placeholder="أدخل كلمة المرور"
          />
          {showpass ? (
            <AiFillEye
              onClick={() => {
                setshowpass(false);
              }}
            />
          ) : (
            <AiFillEyeInvisible
              onClick={() => {
                setshowpass(true);
              }}
            />
          )}
        </div>
        {loading ? <Spin /> : <button>إتمام التسجيل</button>}
      </form>
    </div>
  );
};

export default Login;
