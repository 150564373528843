// FloatingButton.js
import React, { useState } from "react";
import "./FloatingButton.css";
import Chat from ".";

const FloatingButton = ({isNone}) => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };
  const [unReadCount, setUnReadCount] = useState(0);
  return (
    <div>
      <button className="floating-button" onClick={toggleChat}>
        💬
        <span>{unReadCount}</span>
      </button>
      {/* {isChatOpen && ( */}
      <div
        className="chat-popup"
        style={{ display: isChatOpen ? "flex" : "none" }}
      >
        <Chat setUnReadCount={setUnReadCount} unReadCount={unReadCount} />
      </div>
      {/* )} */}
    </div>
  );
};

export default FloatingButton;
