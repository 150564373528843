import axios from 'axios';
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';

const Recode = () => {
  const location=useLocation();
  const {state}=location;
  const {email,code}=state;

  const navigate=useNavigate();
  const [codever,setcodever]=useState("");
  const handlesub=()=>{
    if(code==codever){
      toast.success("الكود صحيح");
      navigate("/resetpass",{state:{...state}})
    }
    else {
      toast.error("الكود خطأ")
    }
  }
  return (
    <div className='signstep2'>
      <div className="form"
            // onSubmit={(e) => {
            //   e.preventDefault();
            //   handlesub();
            // }}
          >
            <input
            value={codever}
              onChange={(e) => {
                setcodever(e.target.value);
              }}
              type="text"
              placeholder="أدخل الكود الذى وصلك على بريدك الالكترونى"
            />
            <button
            onClick={()=>{
              handlesub()
            }}
            >تسجيل</button>
          </div>
    </div>
  )
}

export default Recode
