import React, { useEffect, useState } from "react";
import "../treatments/treatment.css";
import './treatmentlist.css'
import { AiOutlinePlus, AiOutlineQuestion } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router";
import {RiFileList2Fill} from 'react-icons/ri'
import {AiOutlineCheck} from 'react-icons/ai';
import {HiOutlineBan} from 'react-icons/hi'
import {BsArrowLeft} from 'react-icons/bs'
import axios from "axios";
import { Spin } from "antd";
const TreatmentsList = () => {
  const datalocal=localStorage.getItem("loggedin");

  const navigate = useNavigate();
  const [searched,setsearched]=useState(1)
  const [selectedfilt,setselectedfilt]=useState("");
  const [treatmentlist,settreatmentlist]=useState([])
  const [originaltreatments,setoriginaltreatments]=useState([]);
  const [loading,setloading]=useState(true);
  const [searchtxt,setsearchtxt]=useState("");
  const [treataftersearch,settreataftersearch]=useState([])
  const gettreatments=()=>{
    const data_send={
      user_id:JSON.parse(datalocal).user_id||0,
    }
    axios.post("https://ahmed-cash.com/ahmed_cash/user/select_my_order.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res)
      settreatmentlist(res.data.message)
      setoriginaltreatments(res.data.message)
    }).finally(()=>{
      setloading(false);
    }).catch(err=>console.log(err))
  }
  useEffect(()=>{
    gettreatments()
  },[])
  const handleupdatedata=(type)=>{
    // console.log(type)
    if(searchtxt!==""){
      let data=[...treataftersearch];
      if(type=="all"){
        settreatmentlist(treataftersearch);
      }
      else {
        settreatmentlist(data.filter((item)=>{
          if(item.status==type){
            return {...item}
          }
          else return null;
        }));
      }
    }
    else{
      let data=[...originaltreatments];
    // console.log(data)
    if(type=='all'){
      settreatmentlist([...originaltreatments]);
    }
    else {
      settreatmentlist(data.filter((item)=>{
        if(item.status==type){
          return {...item}
        }
        else return null;
      }));
    }
    }

  }

  useEffect(()=>{
    if(searchtxt==""){
      handleupdatedata(selectedfilt);
    }
    else {
      if(selectedfilt!=="all"){
        const data=[...treataftersearch];
        settreatmentlist(data.filter((item)=>{
          if(item.status==selectedfilt&&item.order_id==searchtxt){
            return {...item}
          }
          else return null;
        }));
      }
      else{
        const data=[...treataftersearch];
        settreatmentlist(data.filter((item)=>{
          if(item.order_id==searchtxt){
            return {...item}
          }
          else return null;
        }));
      }
    }
  },[selectedfilt])

  const handlesearch=(e)=>{
    // console.log(e*1)
    let data=[...originaltreatments];
    if(e==""){
      settreatmentlist([...originaltreatments]);
    }
    else {
      settreatmentlist(data.filter((item)=>{
        if(item.order_id*1==e*1){
          return {...item}
        }
        else return null;
      }));
      settreataftersearch(data.filter((item)=>{
        if(item.order_id*1==e*1){
          return {...item}
        }
        else return null;
      }));
    }
  }
  return (
    <div className="treatments_page">

      <div className="treatments treatmenstlist">
        <div className="filter">
        <div className="right">
          <select value={selectedfilt} onChange={(e)=>{
            setselectedfilt(e.target.value);
          }} name="" id="">
            <option onClick={()=>{
              // handleupdatedata("all")
            }} value="all">
              جميع
            </option>
            <option onClick={()=>{
              // handleupdatedata("pending")
            }}  value="pending">
              معلقه
            </option>
            <option onClick={()=>{
              // handleupdatedata("refused")
            }}  value="refused">
              مرفوضه
            </option>
            <option onClick={()=>{
              // handleupdatedata("done")
            }}  value="done">
              مؤكده
            </option>
            <option onClick={()=>{
              // handleupdatedata("cancel")
            }}  value="cancel">
              ملغيه
            </option>
          </select>
        </div>
          <div className="left">
            <input onChange={(e)=>{
              // handlesearch(e);
              if(searched==2){
                if(e.target.value==""){
                  settreatmentlist(originaltreatments)
                  setselectedfilt("all")
                }
              }
              setsearchtxt(e.target.value)

              // if(selectedfilt!=="all"){
              //   let data=[...originaltreatments];
              //   settreatmentlist(data.filter((item)=>{
              //     if(item.status==selectedfilt){
              //       return {...item}
              //     }
              //     else return null;
              //   }));
              // }
              // else {
              //   settreatmentlist(originaltreatments)
              // }
            }} type="text" placeholder="ابحث برقم العمليه"/>
            <button
              onClick={()=>{
                handlesearch(searchtxt)
                setsearched(2)
              }}
            >بحث</button>
          </div>

        </div>
        {/* {
          treatmentlist.length>0?(
            <div className="treatmentsdiv">
          {
            loading?(
              <div style={{textAlign:'center'}}>
                <Spin/>
              </div>
            ):(
              treatmentlist.map((item,index)=>{
                return(
                  <div style={{cursor:'pointer'}} className="treatmentdiv"
                    onClick={()=>{
                      navigate("/treatdetails",{state:{item}})
                    }}
                  >
                    <div className="treatment_icon">
                      {
                        item.status=="pending"?(
                          <RiFileList2Fill
                            // onClick={()=>{
                            //   navigate("/conftreat",{state:{item}})
                            // }}
                          style={{color:"#f6931b"}}/>
                        )
                        :
                        item.status=="done"?
                        (
                          <AiOutlineCheck style={{color:'#00B112'}}/>
                        )
                        :(
                          <HiOutlineBan style={{color:'#bb2d3b'}}/>
                        )
                      }
                    </div>
                    <div className="treatment_status">
                      <div>
                        <h4>{item?.source_Wallet?.wallet_name}</h4>
                        <BsArrowLeft/>
                        <h4>{item?.destination_Wallet?.wallet_name}</h4>
                      </div>
                      <div style={{
                        flexWrap:'wrap'
                      }}>
                        <h4>المبلغ.</h4>
                        <h4 style={{display:'flex',alignItems:'center'}}>{item.amount_sent}<BsArrowLeft/>{item.received_quantity}</h4>
                      </div>
                      {
                        item.status=='refused'?(
                          <h4 style={{marginBottom:'4px'}}>سبب الرفض : {item.refused_reason}</h4>
                        ):(null)
                      }
                      <div>
                        <h4>رقم العملية:</h4>
                        <h4>{item.order_id}({item.status=="pending"?"معلقه":item.status=="done"?"مؤكده":item.status=='cancel'?'ملغيه':"مرفوضه"})</h4>
                      </div>
                    </div>
                  </div>
                )
              })
            )
          }
        </div>
          ):(
            <h4>لا توجد عمليات الان</h4>
          )
        } */}
        {
          loading?(
            <div style={{textAlign:'center'}}>
            <Spin/>
          </div>
          ):(
            treatmentlist?.length>0?(
              treatmentlist.map((item,index)=>{
                return(
                  <div style={{cursor:'pointer'}} className="treatmentdiv"
                    onClick={()=>{
                      navigate("/treatdetails",{state:{item}})
                    }}
                  >
                    <div className="treatment_icon">
                      {
                        item.status=="pending"?(
                          <RiFileList2Fill
                            // onClick={()=>{
                            //   navigate("/conftreat",{state:{item}})
                            // }}
                          style={{color:"#f6931b"}}/>
                        )
                        :
                        item.status=="done"?
                        (
                          <AiOutlineCheck style={{color:'#00B112'}}/>
                        )
                        :(
                          <HiOutlineBan style={{color:'#bb2d3b'}}/>
                        )
                      }
                    </div>
                    <div className="treatment_status">
                      <div>
                        <h4>{item?.source_Wallet?.wallet_name}</h4>
                        <BsArrowLeft/>
                        <h4>{item?.destination_Wallet?.wallet_name}</h4>
                      </div>
                      <div style={{
                        flexWrap:'wrap'
                      }}>
                        <h4>المبلغ.</h4>
                        <h4 style={{display:'flex',alignItems:'center'}}>{item.amount_sent}<BsArrowLeft/>{item.received_quantity}</h4>
                      </div>
                      {
                        item.status=='refused'?(
                          <h4 style={{marginBottom:'4px'}}>سبب الرفض : {item.refused_reason}</h4>
                        ):(null)
                      }
                      <div>
                        <h4>رقم العملية:</h4>
                        <h4>{item.order_id}({item.status=="pending"?"معلقه":item.status=="done"?"مؤكده":item.status=='cancel'?'ملغيه':"مرفوضه"})</h4>
                      </div>
                    </div>
                  </div>
                )
              })
            ):(
                <h4>لا توجد عمليات الان</h4>
            )
          )
        }
        <div className="plustreat"
          onClick={()=>{
            navigate("/")
          }}
        >
          <AiOutlinePlus/>
        </div>
      </div>
    </div>
  );
};

export default TreatmentsList;
