import React, { useEffect, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { useLocation, useNavigate } from "react-router";
import "../confirmtreat/confirmtreat.css";
import { toast } from "react-toastify";
import PopUp from "../popUp";
import axios from "axios";
import "./treatdetails.css";
import { MdContentCopy } from "react-icons/md";
import moment from "moment";
import { Modal } from "antd";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useCallback } from "react";
const Treatdetails = () => {
  const datalocal = localStorage.getItem("loggedin");
  // console.log(JSON.parse(datalocal))
  // console.log(JSON.parse(datalocal))
  const location = useLocation();
  const { item } = location.state;
  console.log(item);
  const [notes, setnotes] = useState("");
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [time, setTime] = useState(
    item.response_time
      ? moment(item.response_day + " " + item.response_time).format("LTS")
      : moment(item.date).format("LTS")
  );
  // console.log(item)
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(false);
  const cancel = () => setShow(false);
  const [client_phone, setClientPhone] = useState("");
  const [confimg, setconfimg] = useState(null);
  const [reson, setreson] = useState("");
  const confirm = () => {
    // toast.success("تم تنفيذ العملية بنجاح");
    const fromdata = new FormData();
    fromdata.append("image", confimg);
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/admin/item_img_uploader.php",
        fromdata
      )
      .then((res) => {
        if (res.data.status == "success") {
          const data_send = {
            wallet_from_id: item.wallet_from_id,
            wallet_to_id: item.wallet_to_id,
            amount_sent: item.amount_sent,
            received_quantity: item.received_quantity,
            rate: item.rate,
            destination_address: item.account,
            source_address: client_phone,
            user_from_id: JSON.parse(datalocal)?.user_id || 0,
            user_photo_url: res.data.message,
          };
          axios
            .post(
              "https://ahmed-cash.com/ahmed_cash/user/make_order.php",
              JSON.stringify(data_send)
            )
            .then((res) => {
              if (res.data.status == "success") {
                toast.success(res.data.message);
                navigate("/treatmentlist");
              } else if (res.data.status == "error") {
                toast.error(res.data.message);
              } else {
                toast.error("حدث خطأ ما");
              }
            })
            .catch((err) => console.log(err));
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((err) => console.log(err));

    setShow(false);
  };
  const [companynumber, setcompanynumber] = useState("");
  const getcompanyphone = () => {
    axios
      .get("https://ahmed-cash.com/ahmed_cash/user/select_wallets.php")
      .then((res) => {
        // let companynumber="";
        // for (let i=0;i<11;i++){

        // }
        // console.log(res.data.message[0].transfare_to_details.split(" "));
        let arr = res.data.message[0]?.transfare_to_details.split(" ");
        // let arrlen=res.data.message[0].transfare_to_details.split(" ").length;
        // console.log(arrlen)
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] * 1 !== "NAN") {
            setcompanynumber(arr[i]);
          }
        }
        // setcompanynumber(res.data.message[0]?.transfare_to_details.splite(" "));
        // console.log(res.data.message[0]?.transfare_to_details.splite(" "))
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getcompanyphone();
  }, []);

  function myFunction() {
    // Get the text field
    var copyText = document.getElementById("myInput");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
  }
  const handlecancel = () => {
    const data_send = {
      order_id: item.order_id,
      refused_reason: reson,
    };
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/cancel_order.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          navigate("/treatmentlist");
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((err) => console.log(err));
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    handlecancel();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const openImageViewer = useCallback((index) => {
    // console.log(index)
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <div className="confirm_page newconf">
        {show ? (
          <PopUp
            title={"رسالة تأكيد"}
            text={"هل أنت متأكد من تأكيد العملية ؟"}
            closeFunction={cancel}
            conFiremFunction={confirm}
          />
        ) : null}
        <div className="confirm_form">
          <h2>بيانات العملية</h2>
          <div className="v_T">
            <h3>الارسال</h3>
            <p className="v_v">
              <table>
                <thead>
                  <tr>
                    <th>نوع</th>
                    <th>القيمة </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "4px",
                      }}
                    >
                      <img
                        style={{ width: "40px" }}
                        src={item.source_Wallet.wallet_logo}
                        alt=""
                      />
                      <span>{item?.source_Wallet.wallet_name}</span>
                    </td>
                    <td>{item.amount_sent}</td>
                  </tr>
                </tbody>
              </table>
            </p>
          </div>
          <div className="v_T">
            <h3>الاستقبال</h3>
            <p className="v_v">
              <table>
                <thead>
                  <tr>
                    <th>نوع</th>
                    <th>القيمة</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "4px",
                        }}
                      >
                        <img
                          style={{ width: "40px" }}
                          src={item.destination_Wallet.wallet_logo}
                          alt=""
                        />
                        <span>{item.destination_Wallet.wallet_name}</span>
                      </span>
                    </td>
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "4px",
                      }}
                    >
                      <span>{item.received_quantity}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </p>
          </div>
          <div>
            <h3>الحاله</h3>
            <p style={{ display: "flex", alignItems: "center" }}>
              {item.status == "pending"
                ? "معلقه"
                : item.status == "done"
                ? "مؤكده"
                : "مرفوضه"}
            </p>
          </div>
          <div>
            <h3>الرقم المحول إليه</h3>
            <p>{item.destination_address}</p>
          </div>
          <div>
            <h3>تاريخ العمليه</h3>
            <p>{moment(item.date).format("YYYY-MM-DD")}</p>
          </div>
          <div>
            <h3>سعر الوحده</h3>
            <p>{item.rate}</p>
          </div>
          <div>
            <h3>وقت العمليه</h3>
            {item.status == "pending" ? (
              <p>{moment(item.date).format("LTS")}</p>
            ) : (
              <p>{time}</p>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {item.confirme_photo_url !== null ? (
              <div>
                <h4 style={{ textAlign: "center", marginBottom: "4px" }}>
                  صورة إثبات التحويل
                </h4>
                <div
                  onClick={() => {
                    openImageViewer(1);
                  }}
                >
                  <TransformWrapper>
                    <TransformComponent>
                      <img
                        style={{ width: "200px", height: "fit-content" }}
                        src={item.confirme_photo_url}
                        alt="test"
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </div>
              </div>
            ) : null}
            {item.user_photo_url !== null && item.user_photo_url !== "" ? (
              <div>
                <h4 style={{ textAlign: "center", marginBottom: "4px" }}>
                  صورة إثبات الدفع
                </h4>
                <div
                  onClick={() => {
                    openImageViewer(0);
                  }}
                >
                  <TransformWrapper>
                    <TransformComponent>
                      <img
                        style={{ width: "200px", height: "fit-content" }}
                        src={item.user_photo_url}
                        alt="test"
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </div>
              </div>
            ) : null}
          </div>

          {isViewerOpen && (
            <ImageViewer
              src={[item.user_photo_url, item.confirme_photo_url]}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          )}

          {/* <p>
          تأكد من جميع البيانات - ثم قم بتحويل المبلغ على رقم الحساب التالى
          وقم باالاحتفاظ ب سكرينه التحويل لاستخدامها عند الحاجه - ثم أدخل الرقم
          الذى أرسلت منه{" "}
        </p>
        <div className="copyinput">
        <input
        value={companynumber}
        id="myInput"
          type="text"
          placeholder="رقم/حساب فودافون كاش"
          // onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <MdContentCopy onClick={()=>{
          myFunction()
        }}/>
        </div>
        <input onChange={(e)=>{
          setClientPhone(e.target.value)
        }} type="text" placeholder="أدخل الرقم الذى حولت منه"/>
        <input type="file" onChange={(e)=>{
          setconfimg(e.target.files[0])
        }}/> */}
          <div className="btns">
            {/* <button
            onClick={() => {
              if (client_phone) {
                setShow(true);
                confirm()
              } else {
                toast.error("برجاء إدخال رقم الهاتف");
              }
            }}
          >
            تأكيد العمليه
          </button> */}
            {item.status == "pending" ? (
              <button
                style={{ width: "100%" }}
                onClick={() => {
                  setIsModalOpen(true);
                  // navigate("/treatments");
                }}
              >
                {" "}
                إلغاء العمليه
              </button>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  width: "100%",
                  height: "50px",
                }}
              >
                <span>
                  {" "}
                  لقد قمت بالدفع عن طريق {item.source_Wallet.wallet_name}
                </span>
                <br />
                <span>من رقم ({item?.source_address || "لا يوجد بيانات"})</span>
                <br />
                <span>
                  وقمت بإرسال المبلغ على رقم (
                  {item.destination_address || "لا يوجد بيانات"})
                </span>
              </p>
            )}
          </div>
        </div>
        <div className="notes">
          <h4>مواعيد العميل</h4>
          {/* <img src={require("../../assets/noteimg.png")} alt="" /> */}
          <p>{notes}</p>
        </div>
      </div>
      <Modal
        style={{ direction: "ltr" }}
        title="الغاء العملية"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <textarea
          onChange={(e) => {
            setreson(e.target.value);
          }}
          style={{
            width: "100%",
            height: "100px",
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            resize: "none",
            direction: "rtl",
            outline: "none",
          }}
          placeholder="أدخل سبب الرفض"
        ></textarea>
      </Modal>
    </>
  );
};

export default Treatdetails;
