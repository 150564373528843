import React, { useState, useEffect, useRef } from "react";
import { ref, push, onValue, remove, update } from "firebase/database";
import "./chat.css";
import { database } from "../../firebase";

// Import your sound file (replace with your actual sound file path)
import messageSound from "./message.mp3";
import moment from "moment";
import axios from "axios";

const Chat = ({ setUnReadCount, unReadCount }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [editMessageId, setEditMessageId] = useState(null);
  const [showMenu, setShowMenu] = useState(null);
  const [visibleMessages, setVisibleMessages] = useState({});
  const datacheck = localStorage.getItem("loggedin");
  const localdata = JSON.parse(datacheck);
  const chatId = localdata?.user_id; // Replace with logic to get the current chat ID
  const menuRef = useRef();

  // Audio element for message notification sound
  const audioRef = useRef(new Audio(messageSound));
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    if (!localdata || !localdata.user_id) {
      console.error("User data is incomplete or undefined");
      return;
    }

    const chatRef = ref(database, `chats/${chatId}/messages`);

    // Fetch the initial messages and listen for new ones
    const unsubscribe = onValue(chatRef, (snapshot) => {
      const data = snapshot.val();
      const newData = data
        ? Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }))
        : [];
      setMessages(newData);

      if (initial) {
        setInitial(false);
      }
    });

    // Cleanup the listener on component unmount
    return () => {
      unsubscribe();
      // Stop playing sound and release resources
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, [chatId, initial]);

  useEffect(() => {
    if (messages.length > 0 && !initial) {
      observeMessages();
    }
  }, [messages, initial]);

  const playMessageSound = () => {
    const audioElement = document.getElementById("messageSound");
    if (audioElement) {
      audioElement.play().catch((error) => {
        console.error("Error playing sound:", error);
      });
    }
  };

  const markMessagesAsRead = async () => {
    const updates = {};
    messages.forEach((msg) => {
      if (
        !msg.read &&
        msg.from !== localdata.user_id &&
        visibleMessages[msg.id]
      ) {
        updates[`chats/${chatId}/messages/${msg.id}/read`] = true;
      }
    });
    try {
      await update(ref(database), updates);
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  };

  const handleSend = async () => {
    if (newMessage.trim() === "") return;

    if (
      !localdata ||
      !localdata.user_id ||
      (!localdata.name && !localdata.user_name)
    ) {
      console.error("User data is incomplete or undefined");
      return;
    }

    try {
      if (editMessageId) {
        const messageRef = ref(
          database,
          `chats/${chatId}/messages/${editMessageId}`
        );
        await update(messageRef, {
          text: newMessage,
          editedAt: moment().format('MMMM Do YYYY, h:mm:ss a'),
        });
        setEditMessageId(null);
      } else {
        const messagesRef = ref(database, `chats/${chatId}/messages`);
        await push(messagesRef, {
          text: newMessage,
          createdAt: moment().format('MMMM Do YYYY, h:mm:ss a'),
          from: localdata.user_id,
          displayName: localdata.name || localdata.user_name,
          to: "admin",
          read: true,
          adminRead: false,
        });
        axios.get("https://ahmed-cash.com/ahmed_cash/user/send_notification_to_email.php")
      }
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this message?")) {
      return;
    }

    try {
      const messageRef = ref(database, `chats/${chatId}/messages/${id}`);
      await remove(messageRef);
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const handleEdit = (msg) => {
    setNewMessage(msg.text);
    setEditMessageId(msg.id);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const handleCancelEdit = () => {
    setNewMessage("");
    setEditMessageId(null);
  };

  const observeMessages = () => {
    const messageElements = document.querySelectorAll(".chat-message");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const messageId = entry.target.getAttribute("data-message-id");
          if (entry.isIntersecting) {
            setVisibleMessages((prev) => ({
              ...prev,
              [messageId]: true,
            }));
            markMessageAsRead(messageId); // Mark message as read when it becomes visible
          } else {
            setVisibleMessages((prev) => ({
              ...prev,
              [messageId]: false,
            }));
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    messageElements.forEach((messageElement) => {
      observer.observe(messageElement);
    });

    return () => {
      messageElements.forEach((messageElement) => {
        observer.unobserve(messageElement);
      });
    };
  };

  const markMessageAsRead = async (messageId) => {
    const message = messages.find((msg) => msg.id === messageId);
    if (message && !message.read && message.from !== localdata.user_id) {
      try {
        await update(ref(database, `chats/${chatId}/messages/${messageId}`), {
          read: true,
        });
      } catch (error) {
        console.error("Error marking message as read:", error);
      }
    }
  };
  useEffect(() => {
    console.log(messages);
    if (setUnReadCount) {
      setUnReadCount(messages?.filter((item) => !item?.read)?.length);
    }
  }, [messages]);

  useEffect(() => {
    if (unReadCount) {
      const audioElement = document.getElementById("messageSound");
      if (audioElement) {
        audioElement.play().catch((error) => {
          console.error("Error playing sound:", error);
        });
      }
    }
  }, [unReadCount]);
  return (
    <div className="chat-container">
      <audio ref={audioRef} id="messageSound" src={messageSound}>
        Your browser does not support the audio tag.
      </audio>
      <div className="chat-messages">
        {messages.map((msg) => (
          <div
            key={msg.id}
            data-message-id={msg.id}
            className={`chat-message ${
              msg.from === localdata.user_id ? "own" : "other"
            } ${msg.read ? "read" : "unread"}`}
          >
            <strong>{msg.displayName}</strong>
            <span>{msg.createdAt}</span>
            {msg.text}
            {msg.adminRead && <span className="seen">Seen</span>}
            {msg.from === localdata.user_id && (
              <div className="menu-container" ref={menuRef}>
                <button
                  onClick={() =>
                    setShowMenu(msg.id === showMenu ? null : msg.id)
                  }
                >
                  &#x22EE;
                </button>
                {showMenu === msg.id && (
                  <div className="menu">
                    <button onClick={() => handleEdit(msg)}>
                      <i className="fa fa-pencil"></i>
                    </button>
                    <button onClick={() => handleDelete(msg.id)}>
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="chat-input-container">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type your message..."
          className="chat-input"
        />
        <button onClick={handleSend} className="chat-send-button">
          {editMessageId ? <>&#9998;</> : <i className="fa fa-paper-plane"></i>}
        </button>
        {editMessageId && (
          <button onClick={handleCancelEdit} className="chat-cancel-button">
            ✕
          </button>
        )}
      </div>
    </div>
  );
};

export default Chat;
