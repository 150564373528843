import { Spin } from "antd";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "./regist2.css";
import { CopyToClipboard } from "react-copy-to-clipboard";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};
const Partice = () => {
  const navigate = useNavigate();

  const [signdata, setsigndata] = useState({
    user_name: "",
    password: "",
    email: "",
    phone: "",
  });
  const [loading, setloading] = useState(false);
  const [confpass, setconfpass] = useState("");
  const [image_path, setimage_path] = React.useState("");
  const [nid_image, setNid_image] = React.useState(false);
  const [name, setName] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const datalocal = localStorage.getItem("loggedin");

  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [address, setAddress] = useState("");
  const [streamVideo, setStreamVideo] = useState("");
  const [nid, setNid] = useState("");
  const [openedCamera, setOpenedCamera] = useState(false);
  const [faceLink, setFaceLink] = useState("");
  const [faceImage, setFaceImage] = useState();
  const [backFaceLink, setBackFaceLink] = useState("");
  const [backFaceimage, setBackFaceimage] = useState();
  const getUSerData = () => {
    const data_send = {
      user_id: JSON.parse(datalocal)?.user_id,
    };
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/select_user_info.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        localStorage.setItem("loggedin", JSON.stringify(res.data.message));
        window.location.href = "/userdata";
      })
      .catch((e) => console.log(e))
      .finally(() => {
        //   setPageLoading(false)
      });
  };
  const uploadImage = (file, setImage) => {
    setUploadLoading(true);
    const formData = new FormData();
    formData.append("image", file);
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/admin/item_img_uploader.php",
        formData
      )
      .then((res) => {
        if (res?.data?.status == "error") {
          toast.error("فشل في رفع الصورة");
        } else {
          setImage(res?.data?.message);
          toast.success("تم رفع الصورة بنجاح");
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setUploadLoading(false);
      });
  };
  const validateEmail = (email) => {
    var re = /^01[0125][0-9]{8}$/;
    var em =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

    return re.test(email);
  };

  const [inviteLink, setInviteLink] = useState(null);
  const handlesub = async (e) => {
    const from = e.target;
    setloading(true);
    let image = null;
    if (signdata?.file) {
      const formData = new FormData();
      formData.append("image", signdata?.file);
      await axios
        .post(
          "https://ahmed-cash.com/ahmed_cash/admin/item_img_uploader.php",
          formData
        )
        .then((res) => {
          if (res?.data?.status == "error") {
            toast.error("فشل في رفع الصورة");
          } else {
            image = res?.data?.message;
            toast.success("تم رفع الصورة بنجاح");
          }
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setUploadLoading(false);
        });
    }
    if (!signdata?.address || !image) {
      setloading(false);
      return toast.error("أكمل البيانات");
    }
    const data_send = {
      ...signdata,
      identity: image,
      user_id: JSON.parse(datalocal)?.user_id,
    };
    await axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/user/make_partner.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          setsigndata({});
          from.reset();
          getUSerData();
          setInviteLink(window.location.origin + "/regist?invitation_code=" + res.data.message);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setloading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="registpage">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handlesub(e);
        }}
      >
        <label htmlFor="">أدخل العنوان </label>
        <input
          onChange={(e) => {
            setsigndata({ ...signdata, address: e.target.value });
          }}
          type="text"
          placeholder="أدخل العنوان "
        />

        <label htmlFor="">أدخل صورة البطاقة </label>
        <input
          onChange={(e) => {
            setsigndata({ ...signdata, file: e.target.files[0] });
          }}
          type="file"
          placeholder="أدخل صورة البطاقة "
        />
        {inviteLink ? (
          <CopyToClipboard
            text={inviteLink}
            onCopy={() => toast.success("تم نسخ رابط الدعوة")}
          >
            <span>{inviteLink}</span>
          </CopyToClipboard>
        ) : null}
        {loading ? (
          <div>
            <Spin />
          </div>
        ) : (
          <button>إتمام الانضمام</button>
        )}
      </form>
    </div>
  );
};

export default Partice;
