import { Space, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

const History = () => {
  const [accepted_data, setaccepted_data] = useState([]);
  const [acc_data, setAccData] = useState(accepted_data);

  const renderdata = [
    {
      title: "رقم العمليه",
      dataIndex: "transaction_id",
      key: "transaction_id",
    },
    {
      title: "الحالة",
      key: "actions",
      render: (_, record) => (
        <Space>
          <div className="actions">
            {record?.order_data?.status == "cancel" ? (
              <span style={{ color: "#085394" }}> عملية ملغية</span>
            ) : record?.order_data?.status == "refused" ? (
              <span style={{ color: "red" }}> عملية مرفوضة</span>
            ) : (
              <span style={{ color: "green" }}> عملية ناجحة</span>
            )}
          </div>
        </Space>
      ),
    },

    {
      title: "رقم المرسل",
      key: "address",
      render: (_, record) => (
        <Space>
          <div className="actions">
            <span>{record?.order_data?.user_from_id}</span>
          </div>
        </Space>
      ),
    },

    {
      title: "رقم محفظة المرسل",
      key: "address",
      render: (_, record) => (
        <Space>
          <div className="actions">
            <span>{record?.order_data?.wallet_from_id}</span>
          </div>
        </Space>
      ),
    },

    {
      title: "المبلغ المرسل",
      dataIndex: "amount_sent",
      key: "amount_sent",
    },
    {
      title: "الأموال المكتسبة",
      dataIndex: "partner_money",
      key: "partner_money",
      render: (_, record) => (
        <Space>
          <div className="wallet_data">
            <span>{parseInt(record?.partner_money)/100}جنية</span>
          </div>
        </Space>
      ),
    },

    {
      title: "تم التحويل بواسطة",
      key: "trans_by",
      render: (_, record) => (
        <Space>
          <div className="wallet_data">
            <span>{record?.order_data?.userData?.name}</span>
          </div>
        </Space>
      ),
    },
  ];

  const datacheck = localStorage.getItem("loggedin");
  const localdata = JSON.parse(datacheck);

  const getData = () => {
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/admin/select_partner_trans_history.php",
        {
          partner_id: localdata?.user_id,
        }
      )
      .then((res) => {
        if (res.data.status == "success") {
          setaccepted_data(res.data.message);
          setAccData(res.data.message);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="accepted_page">
       <div className="user_data_page">
       <div className="user"  style={{width:"fit-content"}}>
      {acc_data && acc_data?.length && (
        <Table dataSource={acc_data} columns={renderdata} />
      )}
    </div>
    </div>
    </div>
  );
};

export default History;
