import { Route, Routes, useLocation, useNavigate } from "react-router";
import Regist from "./components/register/Regist";
import Home from "./components/Home/Home";
import Treatments from "./components/treatments/Treatments";
import ConfirmTreat from "./components/confirmtreat/ConfirmTreat";
import Header from "./components/Header/Header";
import Support from "./components/support/Support";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Step2Sign from "./components/step2sign/Step2Sign";
import Regist2 from "./components/register/Regist2";
import Login from "./components/login/Login";
import TreatmentsList from "./components/treatmentList/TreatmentList";
import Treatdetails from "./components/treatdetails/Treatdetails";
import ForgetPass from "./components/ForgetPass/ForgetPass";
import ResetPass from "./components/ResetPass/ResetPass";
import Recode from "./components/Recode/Recode";
import UserData from "./components/UserData/UserData";
import ResetPassword from "./components/Reset/ResetPassword";
import ReMakePass from "./components/ReMakePass/ReMakePass";
import UserWallets from "./components/UserWallets/UserWallets";
import ConfirmID from "./components/register/confirmId";
import axios from "axios";
import { useEffect, useState } from "react";
import Modal from "./components/modal";
import Partice from "./components/register/participent";
import Clients from "./components/UserData/clients";
import Transactions from "./components/UserData/transactions";
import { analytics, app } from "./firebase";
import FloatingButton from "./components/chat/floatingChat";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState();
  const [userId, setUserId] = useState();
  const [UnVerified, setUnVerified] = useState(false);
  const [checkedIdentity, setCheckedIdentity] = useState(false);
  const chackIdentity = () => {
    axios
      .post("https://ahmed-cash.com/ahmed_cash/user/check_identity.php", {
        user_id: userData?.user_id,
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          setCheckedIdentity(false);
          setUnVerified(false);
        } else {
          setCheckedIdentity(true);
          setUnVerified(true);
        }
      })
      .catch((err) => console.log());
  };
  useEffect(() => {
    try {
      if (location?.pathname !== "/ConfirmID") {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedin"));
        setUserData(loggedInUser);
      } else {
        setUserData(null);
      }
    } catch (err) {
      setUserData(null);
    }
  }, [location, navigate]);

  useEffect(() => {
    setUserId(userData?.user_id);
    if (userData?.user_id && location?.pathname != "/ConfirmID") {
      chackIdentity();
      // setInterval(() => chackIdentity(), 1000);
    } else {
      setCheckedIdentity(false);
    }
  }, [location, navigate, userData, checkedIdentity]);
  useEffect(() => {
    if (UnVerified && !checkedIdentity) {
      setCheckedIdentity(true);
    }
  }, [UnVerified]);
  useEffect(() => {
    console.log(app);
  }, []);
  return (
    <div className="app">
      <FloatingButton />
      <Modal
        headerTitle={"تأكيد الهوية"}
        open={checkedIdentity}
        toggle={() => null}
        children={
          <div>
            <p>اضغط للذهاب لتأكيد الهوية</p>
            <button
              className="btn btn-success"
              onClick={() => {
                setUserData(null);
                navigate("/ConfirmID", {
                  state: { user_id: userId },
                });
              }}
            >
              تأكيد
            </button>
          </div>
        }
      />
      <Header />
      <Routes>
        {userId ? (
          <>
            <Route path="*" element={<Home />} />
            <Route path="/treatments" element={<Treatments />} />
            <Route path="/conftreat" element={<ConfirmTreat />} />
            <Route path="/userwallets" element={<UserWallets />} />
            <Route path="/treatmentlist" element={<TreatmentsList />} />
            <Route path="/treatdetails" element={<Treatdetails />} />
            <Route path="/forgetpass" element={<ForgetPass />} />
            <Route path="/Partice" element={<Partice />} />
            <Route path="/resetpass" element={<ResetPass />} />
            <Route path="/recode" element={<Recode />} />
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="/remakepass" element={<ReMakePass />} />
            <Route path="/userdata" element={<UserData />} />
            <Route path="/ConfirmID" element={<ConfirmID />} />
            <Route path="/Clients" element={<Clients />} />
            <Route path="/Transactions" element={<Transactions />} />
            <Route path="/support" element={<Support />} />
            </>
        ) : (
          <>
            {" "}
            <Route path="/support" element={<Support />} />
            <Route path="/sign" element={<Step2Sign />} />
            <Route path="/regist" element={<Regist2 />} />
            <Route path="*" element={<Login />} />
          </>
        )}{" "}
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
