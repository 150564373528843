import React, { useState } from "react";
import "./style.css";
function PopUp({ title, text, conFiremFunction, closeFunction }) {
  return (
    <div className="popup">
      <div
        className="cancel_o"
        role="button"
        onClick={() => (closeFunction ? closeFunction() : null)}
      >
        إغلاق
      </div>
      <div className="body_popup">
        <div className="popup_header">{title ? title : null}</div>
        <div className="popup_body">{text ? text : null}</div>
      </div>
      <button
        className="popupConfirm"
        onClick={() => (conFiremFunction ? conFiremFunction() : null)}
      >
        تأكيد
      </button>
    </div>
  );
}

export default PopUp;
