import React, { useEffect, useRef, useState } from "react";
import "./regist2.css";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import { Spin } from "antd";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import ImageInput from "../imageInput/ImageInput";
const ConfirmID = () => {
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [image_path, setimage_path] = React.useState("");
  const [nid_image, setNid_image] = React.useState(false);
  const [name, setName] = useState("");
  const [way, setWay] = useState("");
  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [address, setAddress] = useState("");
  const [streamVideo, setStreamVideo] = useState("");
  const [nid, setNid] = useState("");
  const [openedCamera, setOpenedCamera] = useState(false);
  const [faceLink, setFaceLink] = useState("");
  const [faceImage, setFaceImage] = useState();
  const [nName, setNName] = useState("");
  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState();
  useEffect(() => {
    if (image) {
      const blob = dataURLtoFile(image, "identity.png");
      setFaceImage(blob);
    }
  }, [image]);
  const getOCRData = (link) => {
    axios
      .post(
        "https://testzefta22.cognitiveservices.azure.com/computervision/imageanalysis:analyze?api-version=2023-02-01-preview&features=read&language=ar&gender-neutral-caption=False",
        {
          url: link,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": "d473c9121469447aad76ac25bf330bb9",
          },
        }
      )
      .then((res) => {
        let arr = res.data.readResult.content.split("\n");
        arr = arr.slice(arr.indexOf("بطاقة تحقيق الشخصية") + 1, -1);
        setName(arr[0] + " " + arr[1]);
        setAddress(arr[2] + " " + arr[3]);
        setNid(arr[arr.length - 2]);
        setimage_path(link);
        setNid_image(false);
      })
      .catch((err) => toast.error(err.message))
      .finally((done) => {
        setLoader(false);
        setLoaderChecked(false);
      });
  };
  const [UnVerified, setUnVerified] = useState(true);
  const [loaderChecked, setLoaderChecked] = useState(true);
  const chackIdentity = (link, type = null) => {
    axios
      .post("https://ahmed-cash.com/ahmed_cash/user/check_identity.php", {
        user_id: userData?.user_id,
      })
      .then((res) => {
        if (res?.data?.message?.toLowerCase() == "verified") {
          setLoader(false);
          navigate("/");
          return;
        }
        if (type) {
          setLoader(false);
          return;
        }
        setWay(res?.data?.way);
        if (res?.data?.way == 1) {
          getOCRData(link);
        }
        if (res?.data?.way == 0) {
          setLoader(false);
          setWay(res?.data?.way);
        }
      })
      .catch((err) => setLoader(false))
      .finally(() => {
        setLoaderChecked(false);
      });
  };
  const handleTakePhoto = async () => {
    try {
      // Use the stream or pass it to the Camera component
      const canvas = document.createElement('canvas');
      canvas.width = camera.current.videoWidth;
      canvas.height = camera.current.videoHeight;
      const context = canvas.getContext('2d');
      await context.drawImage(
        camera.current,
        0,
        0,
        canvas.width,
        canvas.height
      );

      const dataUrl = await canvas.toDataURL('image/png');
      setImage(dataUrl);
      camera.current.pause();
      if (streamVideo) {
        const tracks = streamVideo.getTracks();
        tracks.forEach((track) => track.stop());
        camera.current.pause();
      }
      setOpenedCamera(false);
    } catch (error) {
      alert("Error accessing camera:", error);
    }
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl?.split(','),
      mime = arr[0]?.match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr?.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr?.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const openCamera = async () => {
    await navigator.permissions.query({ name: 'camera' });

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment', // 'user' for front camera, 'environment' for back camera
        },
      });
      const tracks = stream.getVideoTracks();

      // Set the stream as the source for the video element
      if (camera.current) {
        camera.current.srcObject = stream;
        camera.current.play();
        setOpenedCamera(true);
      }

      // Wait for the video to be loaded and start playing
      await new Promise((resolve) => {
        camera.current.onloadedmetadata = () => {
          resolve();
        };
      });
    } catch (error) {
      alert("Error accessing camera:", error);
    }
  };
  useEffect(() => {
    try {
      const loggedInUser = JSON.parse(localStorage.getItem("loggedin"));
      setUserData(loggedInUser);
    } catch (err) {
      setUserData(null);
    }
  }, []);
  const uploadImage = (file, setImage) => {
    setUploadLoading(true);
    setLoader(true);
    const formData = new FormData();
    formData.append("image", file);
    axios
      .post(
        "https://ahmed-cash.com/ahmed_cash/admin/item_img_uploader.php",
        formData
      )
      .then((res) => {
        if (res?.data?.status == "error") {
          toast.error("فشل في رفع الصورة");
        } else {
          setImage(res?.data?.message);
          chackIdentity(res?.data?.message);
          toast.success("تم رفع الصورة بنجاح");
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoader(false);
      })
      .finally(() => {
        setUploadLoading(false);
      });
  };
  const handlesub = async () => {
    if (!nName && !nName.length) {
      toast.error("الرجاء تأكيد الاسم كما هو ف البطاقة");
      return;
    }
    if (
      nid
        ?.split(" ")
        ?.reverse()
        ?.join("")
        ?.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d)).length === 14 &&
      nid
        .split(" ")
        .reverse()
        .join("")
        .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d)) *
        0 ===
        0 &&
      way === "1"
    ) {
      await axios
        .post(
          "https://ahmed-cash.com/ahmed_cash/user/edit_confirm_identity.php",
          {
            user_id: userData?.user_id,
            confirm_identity_front: faceLink,
            n_id:
              nid !== "" && nid
                ? nid
                    .split(" ")
                    .reverse()
                    .join("")
                    .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
                : "",
            full_name: nName,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            setImage(null);
            setNid(null);
            setFaceImage(null);
            setFaceLink(null);
            setNName(null);
            navigate("/");
            toast.success(res.data.message);
            return;
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error("يجب عليك تسجيل الدخول أولا");
          }
        })
        .finally(() => {
          setloading(false);
        })
        .catch((err) => console.log(err));
    } else if (way === "0") {
      await axios
        .post(
          "https://ahmed-cash.com/ahmed_cash/user/edit_confirm_identity.php",
          {
            user_id: userData?.user_id,
            confirm_identity_front: faceLink,
            n_id: null,
            full_name: nName,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            setImage(null);
            setNid(null);
            setFaceImage(null);
            setFaceLink(null);
            setNName(null);
            navigate("/");
            toast.success(res.data.message);
            return;
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error("يجب عليك تسجيل الدخول أولا");
          }
        })
        .finally(() => {
          setloading(false);
        })
        .catch((err) => console.log(err));
    } else {
      toast.error("الرجاء رفع صورة واضحة");
      setUnVerified(false);
    }
  };
  useEffect(() => {
    if (!localStorage.getItem("loggedin")) {
      toast.error("يجب عليك تسجيل الدخول أولا");
      navigate("/");
    }
    try {
      const loggedInUser = JSON.parse(localStorage.getItem("loggedin"));
      setUserData(loggedInUser);
    } catch (err) {
      setUserData(null);
    }
  }, []);
  useEffect(() => {
    if (nid !== "" && nid) {
      if (
        nid
          .split(" ")
          .reverse()
          .join("")
          .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d)).length == 14 &&
        nid
          .split(" ")
          .reverse()
          .join("")
          .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d)) *
          0 ==
          0
      ) {
        setUnVerified(false);
      } else {
        setUnVerified(4);
      }
    } else {
      setUnVerified(4);
    }
  }, [nid]);
  useEffect(() => {
    chackIdentity(null, 1);
  }, [userData]);
  useEffect(() => {
    if (loaderChecked) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial";
    }
  }, [loaderChecked]);
  return (
    <div className="registpage">
      {loaderChecked ? (
        <div className="loaderChecked">
          <Spin style={{ color: "white" }} />
        </div>
      ) : null}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return !loader ? handlesub() : null;
        }}
      >
        <label htmlFor="">تأكيد الهوية</label>
        <div className="inputField">
          <label htmlFor="">صورة البطاقة </label>
          <div className="imageUploader">
            <div className="videoCamera" style={{ width: "240px" }}>
              <div className="videoCamera" style={{ width: "100%" }}>
                <video
                  ref={camera}
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                  }}
                  autoPlay
                  playsInline
                  muted
                />
                {openedCamera ? null : (
                  <div
                    style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                  >
                    <span className="btn btn-primary" onClick={openCamera}>
                      فتح الكاميرا
                    </span>
                    {faceImage ? (
                      <span
                        className="btn btn-success"
                        onClick={() => {
                          return uploadLoading
                            ? null
                            : uploadImage(faceImage, setFaceLink);
                        }}
                      >
                        {uploadLoading ? (
                          <Spin style={{ color: "white" }} />
                        ) : (
                          "رفع الصورة"
                        )}
                      </span>
                    ) : null}
                  </div>
                )}
                {!openedCamera ? null : (
                  <span className="btn btn-primary" onClick={handleTakePhoto}>
                    التقاط
                  </span>
                )}{" "}
                {image && (
                  <div>
                    <label htmlFor="">
                      الرجاء إدخال الاسم كما هو ف البطاقة
                    </label>

                    <input
                      type="text"
                      value={nName}
                      onChange={(e) => setNName(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <>
          {loading ? (
            <div>
              <Spin />
            </div>
          ) : (
            <button>
              {!loader ? "تأكيد" : <Spin style={{ color: "white" }} />}
            </button>
          )}
        </>
      </form>
    </div>
  );
};

export default ConfirmID;
